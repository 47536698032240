import axios from 'axios'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { v4 as uuidV4 } from 'uuid'

import { getS3SignedUrl, uploadImageToS3 } from 'src/pages/promoter/commands'
import constants from 'src/constants'

import styles from './index.module.css'

export const UploadDefaultImageModal = (props) => {
  const {
    defaultImageInputRef,
    defaultUserImages,
    selectedFile,
    setSelectedFile,
    setUploadDefaultImageModalIsOpen,
    setUserSelectedDefaultPhoto,
    uploadDefaultImageModalIsOpen,
    userSelectedDefaultPhoto,
  } = props

  const handleClose = () => {
    setUploadDefaultImageModalIsOpen(false)
    setSelectedFile({})
  }

  const handleUpload = async () => {
    const imageId = userSelectedDefaultPhoto.id ? userSelectedDefaultPhoto.id : uuidV4()

    const { s3Location, signedUrl } = await getS3SignedUrl({
      file: defaultImageInputRef.current.details,
    })

    let uploadResult = await uploadImageToS3({
      file: defaultImageInputRef.current.file,
      signedUrl,
    })

    if (uploadResult && uploadResult.status === 200) {
      const newDefaultImage = {
        filename: defaultImageInputRef.current.file.name,
        id: imageId,
        s3Location,
      }

      try {
        await axios.post('/api/images/image/save', newDefaultImage)
      } catch (error) {
        console.error('Could not save default image', error)
      }

      const previousDefaultImages = userSelectedDefaultPhoto.id
        ? R.reject(R.propEq('id', userSelectedDefaultPhoto.id), R.map(R.pick(['id', 'type']), defaultUserImages))
        : R.map(R.pick(['id', 'type']), defaultUserImages)

      const imagesToUpdate = [
        ...previousDefaultImages,
        { id: newDefaultImage.id, type: defaultImageInputRef.current.documentType },
      ]

      try {
        await axios.post(constants.routes.promoter.saveInfo, {
          imageInformation: {
            imageId,
            s3Location,
            type: defaultImageInputRef.current.documentType,
          },
          images: imagesToUpdate,
        })
      } catch (error) {
        console.error('Could not save default image', error)
      }
    }

    setUserSelectedDefaultPhoto({})
    setSelectedFile({})
    setUploadDefaultImageModalIsOpen(false)
  }

  const imageContainerStyles = R.isEmpty(defaultImageInputRef.current)
    ? `${styles.uploadImageContainer} ${styles.uploadImageContainerBorder}`
    : `${styles.uploadImageContainer}`

  return uploadDefaultImageModalIsOpen && !R.isEmpty(defaultImageInputRef.current) ? (
    <div className={styles.updloadDefaultImageContainer}>
      <div className={styles.uploadHeaderContainer}>
        <p>Upload your image</p>
      </div>
      <div className={styles.uploadInformationContainer}>
        <p>Accepted file types: PNG, JPG, GIF, SVG</p>
        <div className={imageContainerStyles}>
          <img src={selectedFile.file} />
        </div>
        <div className={styles.buttonsContainer}>
          <button className={styles.cancelButton} onClick={handleClose}>
            Cancel
          </button>
          <button className={styles.uploadButton} onClick={handleUpload}>
            Upload
          </button>
        </div>
      </div>
    </div>
  ) : null
}

UploadDefaultImageModal.propTypes = {
  defaultImageInputRef: PropTypes.object,
  defaultUserImages: PropTypes.array,
  selectedFile: PropTypes.object,
  setSelectedFile: PropTypes.func,
  setUploadDefaultImageModalIsOpen: PropTypes.func,
  setUserSelectedDefaultPhoto: PropTypes.func,
  uploadDefaultImageModalIsOpen: PropTypes.bool,
  userSelectedDefaultPhoto: PropTypes.object,
}
