import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import Select from '@material-ui/core/Select'

import { makeStyles } from '@material-ui/core/styles'

import * as materialStyles from './styles.js'
import styles from './index.module.css'

const useListStyles = makeStyles({
  icon: materialStyles.deleteImageIcon.root,
  root: materialStyles.list.root,
  select: materialStyles.imageSelect.root,
})

export const Images = (props) => {
  const { deleteImageHandler, images, setImages } = props

  const listStyles = useListStyles()

  const handleImageTypeChange = (event, currentImage, index) => {
    const type = event.target.value

    const updatedImage = {
      ...currentImage,
      type,
    }

    const newImages = R.update(index, updatedImage, images)

    setImages(newImages)
  }

  return (
    <div className={styles.selectionContainer}>
      <button className={styles.button} onClick={(event) => props.createImageHandler(event)}>
        Add Image
      </button>

      <p className={styles.pageRef}>Page 1</p>
      <div className={styles.imagesList}>
        <Divider />
        <List classes={{ root: listStyles.root }}>
          {images.map((image, index) => {
            return (
              <ListItem divider key={image.id}>
                <div className={styles.imagesContainer}>
                  <div className={styles.imageLabelNumber}>{index + 1}</div>
                  <div className={styles.imageAndIcon}>
                    <ListItemText className={styles.imageLabel} primary={image.type} />
                    <ListItemIcon>
                      <DeleteOutlineIcon
                        classes={{ root: listStyles.icon }}
                        onClick={() => deleteImageHandler(image.id)}
                      />
                    </ListItemIcon>
                  </div>
                  <FormControl classes={{ root: listStyles.select }}>
                    <InputLabel shrink id="image-type">
                      Image Type
                    </InputLabel>
                    <Select
                      labelId="image-type-label"
                      id="image-type"
                      value={image.type}
                      onChange={(event) => handleImageTypeChange(event, image, index)}
                      displayEmpty
                    >
                      <MenuItem value={'Choose an image type'}>
                        <em>Choose an image type</em>
                      </MenuItem>
                      <MenuItem value={'headshot'}>Headshot</MenuItem>
                      <MenuItem value={'logo'}>Logo</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </ListItem>
            )
          })}
        </List>
      </div>
    </div>
  )
}

Images.propTypes = {
  createImageHandler: PropTypes.func,
  deleteImageHandler: PropTypes.func,
  images: PropTypes.array,
  setImages: PropTypes.func,
}
