import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Divider from '@material-ui/core/Divider'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import * as R from 'ramda'

import { makeStyles } from '@material-ui/core/styles'

import * as updateProviderPdfTemplateCommands from '../update-provider-pdf-template-commands'
import { TextComponent } from '../text-component'
import { TextFieldForm } from './text-field-form'

import styles from './index.module.css'
import * as materialStyles from './styles.js'

const useAccordionStyles = makeStyles({
  root: materialStyles.accordion.root,
})

export const Text = (props) => {
  const {
    createTextHandler,
    currentFocus,
    handleDeleteText,
    providerPdfTemplateId,
    setCurrentFocus,
    setTexts,
    texts,
  } = props

  const accordionStyles = useAccordionStyles()

  const handleTextLabelChange = async (event, text) => {
    const index = R.findIndex(R.propEq('id', text.id), texts)
    const newLabel = event.target.value

    const component = <TextComponent newCount={text.count} text={{ ...text, label: newLabel }} />

    const newText = {
      ...text,
      component,
      label: newLabel,
    }

    const newTexts = R.update(index, newText, texts)

    setTexts(newTexts)

    await updateProviderPdfTemplateCommands.saveTemplate({ id: providerPdfTemplateId, texts: newTexts })
  }

  return (
    <div className={styles.selectionContainer}>
      <button
        className={styles.button}
        onClick={() => {
          createTextHandler()
        }}
      >
        Add a text field
      </button>
      <p className={styles.pageRef}>Page 1</p>
      <div className={styles.accordion}>
        <Divider />
        {texts.map((text, index) => {
          return (
            <div key={text.id}>
              <Accordion
                key={text.id}
                defaultExpanded={text.expanded}
                square={false}
                classes={{ root: accordionStyles.root }}
              >
                <AccordionSummary aria-controls="panel1a-content" expandIcon={<ExpandMoreIcon />} id="panel1a-header">
                  <>
                    <div className={styles.textLabelNumber}>{++index}</div>
                    {text.type === 'customText' ? (
                      <input
                        className={styles.label}
                        type="text"
                        id={text.id}
                        key={text.id}
                        name={text.label}
                        onChange={() => handleTextLabelChange(event, text)}
                        defaultValue={text.label}
                      />
                    ) : (
                      <input
                        className={styles.label}
                        disabled
                        type="text"
                        id={text.id}
                        name={text.textField}
                        value={text.textField}
                      />
                    )}
                  </>
                </AccordionSummary>
                <div className={styles.form}>
                  <TextFieldForm
                    currentFocus={currentFocus}
                    handleDeleteText={handleDeleteText}
                    providerPdfTemplateId={providerPdfTemplateId}
                    setCurrentFocus={setCurrentFocus}
                    setTexts={setTexts}
                    textId={text.id}
                    texts={texts}
                    text={text}
                  />
                </div>
                <AccordionDetails></AccordionDetails>
              </Accordion>
              <Divider />
            </div>
          )
        })}
      </div>
    </div>
  )
}

Text.propTypes = {
  createImageHandler: PropTypes.func,
  createTextHandler: PropTypes.func,
  currentFocus: PropTypes.object,
  deleteImageHandler: PropTypes.func,
  handleDeleteText: PropTypes.func,
  images: PropTypes.array,
  itemCount: PropTypes.number,
  providerPdfTemplateId: PropTypes.string,
  setCurrentFocus: PropTypes.func,
  setItemCount: PropTypes.func,
  setTexts: PropTypes.func,
  textRef: PropTypes.func,
  texts: PropTypes.array,
}
