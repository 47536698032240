import ReactDOM from 'react-dom'

import { Routes } from './routes'
import * as serviceWorker from './serviceWorker'

import './index.css'

ReactDOM.render(<Routes />, document.getElementById('root'))

// if you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
