import axios from 'axios'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import constants from 'src/constants'
import { ReactComponent as CloseIcon } from 'src/images/close-icon.svg'

import styles from './rename-document-modal.module.css'

const documentNamePlaceholder = 'Document Name'

export const RenameDocumentModal = (props) => {
  const { renameDocumentModal, setRenameDocumentModal } = props

  const [newDocumentName, setNewDocumentName] = useState(documentNamePlaceholder)

  useEffect(() => {
    const close = (event) => {
      if (event.keyCode === 27) {
        setRenameDocumentModal({ isOpen: false, pdfId: null })
      }
    }

    window.addEventListener('keydown', close)

    return () => window.removeEventListener('keydown', close)
  }, [])

  const handleNameChange = (event) => {
    setNewDocumentName(event.target.value)
  }

  const handleSubmit = async () => {
    try {
      const endpoint = constants.routes.provider.providerPdfs

      await axios.post(`${endpoint}/${renameDocumentModal.pdfId}/filename`, {
        filename: newDocumentName,
      })
    } catch (error) {
      // TO DO... put up error modal if fails
      console.log('Error in renaming promoter document', error)
    }

    setNewDocumentName(documentNamePlaceholder)
    setRenameDocumentModal({ isOpen: false, pdfId: null })
  }

  return renameDocumentModal.isOpen ? (
    <div className={styles.renameDocumentModalContainer}>
      <div className={styles.modalHeaderContainer}>
        <p>Rename Document</p>
        <button
          className={styles.closeIcon}
          onClick={() => {
            setRenameDocumentModal({ isOpen: false, pdfId: null })
          }}
        >
          <CloseIcon />
        </button>
      </div>
      <div className={styles.inputAndUpdateContainer}>
        <div className={styles.inputContainer}>
          <form onSubmit={() => console.log('handle this')}>
            <label>
              New Document Name
              <input type="text" value={newDocumentName} onChange={() => handleNameChange(event)} />
            </label>
          </form>
        </div>
        <div className={styles.updateButtonContainer} onClick={handleSubmit}>
          <button>Update Name</button>
        </div>
      </div>
    </div>
  ) : null
}

RenameDocumentModal.propTypes = {
  renameDocumentModal: PropTypes.object,
  setRenameDocumentModal: PropTypes.func,
}
