import * as R from 'ramda'

import * as updateProviderPdfTemplateCommands from '../update-provider-pdf-template-commands'
import { TextComponent } from '../text-component'

export const handleStyleOptionsChange = async (items, providerPdfTemplateId) => {
  const { newStyles = {}, setCurrentFocus, setStyleOptions, setTexts, styleOptions, textField, textId, texts } = items

  setCurrentFocus({ id: textId, type: 'text' })

  const currentText = R.find(R.propEq('id', textId), texts)
  const index = R.findIndex(R.propEq('id', textId), texts)
  const count = currentText.count

  const newStyleOptions = {
    ...styleOptions,
    ...newStyles,
  }

  setStyleOptions(newStyleOptions)

  const component = (
    <TextComponent newCount={count} text={{ ...currentText, styleOptions: newStyleOptions, textField }} />
  )

  const newText = {
    ...currentText,
    component,
    styleOptions: newStyleOptions,
  }

  const newTexts = R.update(index, newText, texts)

  setTexts(newTexts)

  await updateProviderPdfTemplateCommands.saveTemplate({ id: providerPdfTemplateId, texts: newTexts })
}
