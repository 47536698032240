import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useState } from 'react'

import styles from './add-new-group-modal.module.css'
import sharedStyles from './shared.module.css'

export function AddNewGroupModal(props) {
  const { promoterGroups, setPromoterGroups, showAddNewGroupModal, setShowAddNewGroupModal } = props

  const [groupName, setGroupName] = useState('')
  const [showNoNameWarning, setShowNoNameWarning] = useState(false)

  const handleSave = async (event) => {
    event.preventDefault()
    if (groupName.length === 0) {
      setShowNoNameWarning(true)

      return
    }

    setShowNoNameWarning(false)

    try {
      const { data } = await axios.post('/api/provider/groups', { displayName: groupName })

      setPromoterGroups([...promoterGroups, data])

      setShowAddNewGroupModal(false)
    } catch (error) {
      console.log('Could not add new promoter group', error)
    }
  }

  const handleChange = (event) => {
    setGroupName(event.target.value)
  }

  return showAddNewGroupModal ? (
    <div className={sharedStyles.modalContainer}>
      <div className={sharedStyles.modalHeaderContainer}>
        <h1>Add New Promoter Group</h1>
        <IconButton
          onClick={() => {
            setShowNoNameWarning(false)
            setShowAddNewGroupModal(false)
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={sharedStyles.inputsContainer}>
        <form onSubmit={handleSave}>
          <div className={sharedStyles.inputContainer}>
            <span htmlFor="groupInput" className={styles.spanThing}>
              <input
                className={styles.groupInput}
                id="groupInput"
                onChange={handleChange}
                name="groupName"
                placeholder="New Group Name"
                type="text"
                value={groupName}
              />
            </span>
          </div>
          <div className={sharedStyles.submitContainer}>
            <button className={sharedStyles.submitButton} type="submit">
              Save
            </button>
          </div>
        </form>
        {showNoNameWarning ? <div className={styles.warning}>Please type in a group name.</div> : null}
      </div>
    </div>
  ) : null
}

AddNewGroupModal.propTypes = {
  promoterGroups: PropTypes.object,
  setPromoterGroups: PropTypes.func,
  setShowAddNewGroupModal: PropTypes.func,
  showAddNewGroupModal: PropTypes.bool,
}
