import AvatarEditor from 'react-avatar-editor'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import React from 'react'
import Slider from '@material-ui/core/Slider'
import * as R from 'ramda'
import * as uuid from 'uuid'

import { getS3SignedUrl, updatePromoterPdfTemplate, uploadImageToS3 } from '../../commands'

import styles from './edit-image-modal.module.css'

export class EditImageModal extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      editor: null,
      imageUrl: null,
      isUploading: false,
      scale: 1,
    }
  }

  setEditorRef = (editor) => this.setState({ editor })

  handleEditImageSave = async () => {
    const { editor } = this.state
    if (editor !== null) {
      const canvas = editor.getImage()

      const toUrl = canvas.toDataURL()
      let updatedUrl, file

      const imageId = uuid.v4()
      let type

      await fetch(toUrl)
        .then((res) => res.blob())
        .then((blob) => {
          updatedUrl = window.URL.createObjectURL(blob)
          type = blob.type.split('/')[1]
          file = new File([blob], `${this.props.imageToEdit.label}-${imageId}.${type}`, { type })
          return this.setState({ imageUrl: updatedUrl })
        })

      this.setState({ isUploading: true })

      const { s3Location, signedUrl } = await getS3SignedUrl({
        file,
        name: `${this.props.imageToEdit.label}-${imageId}`,
        type,
      })

      const uploadResult = await uploadImageToS3({ file, signedUrl })

      if (uploadResult.status === 200) {
        const newImage = {
          filename: file.name,
          id: imageId,
          s3Location,
        }

        try {
          await axios.post('/api/images/image/save', newImage)
        } catch (error) {
          console.error('Could not save new image', error)
        }

        const updatedImages = R.map((image) => {
          const newType = image.type.includes('Cropped') ? image.type : this.props.imageToEdit.type.concat(' Cropped')

          if (image.id === this.props.imageToEdit.id) return { ...image, id: newImage.id, s3Location, type: newType }

          return image
        })(this.props.images)

        await updatePromoterPdfTemplate({
          promoterPdfTemplateId: this.props.promoterPdfTemplateId,
          promoterPdfTemplateUpdates: { images: updatedImages },
        })

        const newImageToEdit = R.find(R.propEq('id', newImage.id), updatedImages)

        this.props.setImages(updatedImages)
        this.props.setImageToEdit(newImageToEdit)
        this.props.setUpdatePage(!this.props.updatePage)
        this.props.setEditImageModalOpen(false)
        this.setState({ isUploading: false })
      }
    }
  }

  handleSlider = (event, newValue) => {
    return this.setState({ scale: newValue })
  }

  handleImageChange = () => {
    const { editor } = this.state

    if (editor !== null) {
      const canvas = editor.getImage()

      const toUrl = canvas.toDataURL()
      let updatedUrl

      fetch(toUrl)
        .then((res) => res.blob())
        .then((blob) => {
          updatedUrl = window.URL.createObjectURL(blob)

          return this.setState({ imageUrl: updatedUrl })
        })
    }
  }

  render() {
    const { imageToEdit, setImageToEdit, setEditImageModalOpen } = this.props

    const handleClose = () => {
      setEditImageModalOpen(false)
      setImageToEdit(null)
    }

    return (
      <div className={styles.editImageModal}>
        <div className={styles.editImageModalContentContainer}>
          <div className={styles.closeButton}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles.avatarEditorContainter}>
            <AvatarEditor
              border={50}
              color={[255, 255, 255, 0.6]}
              crossOrigin="anonymous"
              height={imageToEdit.location.height}
              image={imageToEdit.s3Location}
              ref={this.setEditorRef}
              rotate={0}
              scale={this.state.scale}
              width={imageToEdit.location.width}
              onImageChange={this.handleImageChange}
            />
          </div>
          <div className={styles.slider}>
            <p>Zoom:</p>
            <Slider max={6} min={1} onChange={this.handleSlider} scale={(x) => x} step={0.2} />
          </div>
          <Button onClick={this.handleEditImageSave}>Save</Button>
        </div>
      </div>
    )
  }
}

EditImageModal.propTypes = {
  editImageModalOpen: PropTypes.bool,
  imageToEdit: PropTypes.object,
  images: PropTypes.array,
  promoterPdfTemplateId: PropTypes.string,
  setEditImageModalOpen: PropTypes.func,
  setImageToEdit: PropTypes.func,
  setImages: PropTypes.func,
  setUpdatePage: PropTypes.func,
  updatePage: PropTypes.bool,
}
