import { HeaderTabs } from './header-tabs'
import { ReactComponent as PieceKeeperLogoIcon } from 'src/images/piece-keeper-logo.svg'

import styles from './index.module.css'

export const HeaderNav = () => {
  return (
    <nav className={styles.headerNav}>
      <PieceKeeperLogoIcon className={styles.pieceKeeperLogo} />
      <HeaderTabs />
    </nav>
  )
}
