import PropTypes from 'prop-types'

import constants from '../../../../constants'
import { ImageNeeded } from './image-needed'
import { PromoterImage } from './promoter-image'

import styles from './index.module.css'

export const Images = (props) => {
  const {
    imageToEdit,
    images,
    imageEditorDrawerOpen,
    setImageToEdit,
    setImageEditorDrawerOpen,
    setEditImageModalOpen,
  } = props

  return (
    <div className={styles.imagesContainer}>
      {images.map((image, index) => {
        const userImage =
          image.id === constants.defaultProviderImageId ? (
            <ImageNeeded
              image={image}
              imageIndex={++index}
              imageEditorDrawerOpen={imageEditorDrawerOpen}
              setImageEditorDrawerOpen={setImageEditorDrawerOpen}
              setImageToEdit={setImageToEdit}
            />
          ) : (
            <PromoterImage
              image={image}
              imageIndex={++index}
              imageToEdit={imageToEdit}
              setEditImageModalOpen={setEditImageModalOpen}
              setImageEditorDrawerOpen={setImageEditorDrawerOpen}
              setImageToEdit={setImageToEdit}
            />
          )
        return <div key={index}>{userImage}</div>
      })}
    </div>
  )
}

Images.propTypes = {
  createImageHandler: PropTypes.func,
  deleteImageHandler: PropTypes.func,
  imageEditorDrawerOpen: PropTypes.bool,
  imageToEdit: PropTypes.object,
  images: PropTypes.array,
  setEditImageModalOpen: PropTypes.func,
  setImageEditorDrawerOpen: PropTypes.func,
  setImageToEdit: PropTypes.func,
}
