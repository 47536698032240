import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import axios from 'axios'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ClearIcon from '@material-ui/icons/Clear'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorIcon from '@material-ui/icons/Error'
import { green } from '@material-ui/core/colors'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ReactComponent as CloudUploadIcon } from 'src/images/cloud-upload-icon.svg'
import { ReactComponent as UploadIcon } from 'src/images/upload-icon.svg'

import styles from './top-menu-bar.module.css'

const PublishMessage = ({ success, setShowPublishMessage }) => {
  return (
    <div className={styles.successfulPublishContainer}>
      {success === true ? (
        <div>
          <div className={styles.exitPublishMessage}>
            <ClearIcon onClick={() => setShowPublishMessage({ show: false, success: null })} />
          </div>
          <div className={styles.successfulPublish}>
            <CheckCircleIcon style={{ color: green[500] }} />
            <p>Successfully Published</p>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.exitPublishMessage}>
            <ClearIcon onClick={() => setShowPublishMessage({ show: false, success: null })} />
          </div>
          <div className={styles.failedPublish}>
            <ErrorIcon color="secondary" />
            <p>Publish failed</p>
          </div>
        </div>
      )}
    </div>
  )
}

PublishMessage.propTypes = {
  setShowPublishMessage: PropTypes.func,
  success: PropTypes.any,
}

export const TopMenuBar = (props) => {
  const { providerPdfTemplateId, setShowUploadPdfModal } = props

  const [loading, setLoading] = useState(false)
  const [showPublishMessage, setShowPublishMessage] = useState({ show: false, success: null })
  const history = useHistory()

  const handlePublish = async () => {
    setLoading(true)
    try {
      const publishResponse = await axios.post(`/api/provider/template/${providerPdfTemplateId}/publish`)

      if (publishResponse.status === 201) {
        setLoading(false)
        setShowPublishMessage({ show: true, success: true })
        setTimeout(() => {
          setShowPublishMessage({ show: false, success: null })
        }, 3000)
      }
    } catch (error) {
      setLoading(false)
      setShowPublishMessage({ show: true, success: false })
      setTimeout(() => {
        setShowPublishMessage({ show: false, success: null })
      }, 3000)
      console.log(error)
    }
  }

  return (
    <div className={styles.topMenuBar}>
      <div className={styles.companyOverviewNav}>
        <ArrowBackIcon
          className={styles.topMenuBarBackButton}
          style={{ fontSize: '15px' }}
          onClick={() => history.goBack()}
        />
        <h2 className={styles.topMeuBarBackLabel}>{props.documentName}</h2>
      </div>
      <div className={styles.buttonsContainer}>
        <div className={styles.replaceDocumentContainer}>
          <button
            className={styles.button}
            onClick={() => setShowUploadPdfModal({ isOpen: true, providerPdfTemplateId: providerPdfTemplateId })}
          >
            <UploadIcon />
            Replace Document
          </button>
        </div>
        <div className={`${styles.buttonsContainer} ${styles.cloudUploadIconContainer}`}>
          <button className={styles.button} onClick={(e) => handlePublish(e)}>
            <CloudUploadIcon className={styles.cloudUploadIcon} />
            PUBLISH
          </button>
        </div>
      </div>

      {loading === true ? (
        <div className={styles.loadingContainer}>
          <CircularProgress />
          <p>Loading...</p>
        </div>
      ) : null}
      {showPublishMessage.show === true ? (
        <PublishMessage setShowPublishMessage={setShowPublishMessage} success={showPublishMessage.success} />
      ) : null}
    </div>
  )
}

TopMenuBar.propTypes = {
  documentName: PropTypes.string,
  images: PropTypes.array,
  numberOfPdfPages: PropTypes.number,
  pdfPage: PropTypes.number,
  providerPdfTemplateId: PropTypes.string,
  scalingFactor: PropTypes.object,
  setPdfPage: PropTypes.func,
  setShowUploadPdfModal: PropTypes.func,
  texts: PropTypes.array,
}
