export const accordion = {
  root: {
    'box-shadow': 'none',
  },
}

export const deleteText = {
  color: '#333333',
}

export const imageSettingsButton = {
  minWidth: 'unset',
}

export const styleInput = {
  halfInput: {
    margin: '15px auto 0 auto',
    width: '45%',
  },
  inputButtons: {
    padding: '7px',
  },
  inputButtonsContainer: {
    margin: '15px 0 0 0',
    padding: '15px 5px 0 0',
    width: '50%',
  },
  root: {
    'background-color': '#f7f7f7',
    margin: '0 auto 0 auto',
    width: '100%',
  },
  withTopMargin: {
    'background-color': '#f7f7f7',
    margin: '15px auto 0 auto',
    width: '100%',
  },
}

export const list = {
  root: {
    padding: 0,
  },
}

export const tab = {
  root: {
    flex: 1,
    'min-width': '10px',
  },
}

export const tabNavigation = {
  flexContainer: {
    'border-bottom': 'solid #979797 1px',
    'justify-content': 'space-between',
  },
  root: {
    margin: '0 auto 0 auto',
    width: '75%',
  },
}

export const texts = {
  root: {
    '& .MuiInput-underline': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },
    backgroundColor: '#f7f7f7',
    borderBottom: '1px solid #979797 ',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    marginTop: '3px',
    padding: '4px',
    width: '100%',
  },
}

export const missingTexts = {
  button: {
    backgroundColor: '#31383E',
    color: '#ffffff',
    marginTop: '-10px',
  },
  helperText: {
    color: '#d10000',
  },
  input: {
    '& .MuiFormHelperText-root': {
      color: '#d10000',
    },
    '& .MuiInput-root': {
      backgroundColor: '#f7f7f7',
    },
  },
  root: {
    '& .MuiFormControl-root': {
      backgroundColor: '#f7f7f7',
      borderBottom: '1px solid #d10000',
    },
    '& .MuiFormLabel-root': {
      color: '#d10000',
    },
    '& .MuiInput-underline': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },
    backgroundColor: '#f7f7f7',
    borderBottom: '1px solid #d10000',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    marginTop: '3px',
    padding: '4px',
    width: '100%',
  },
}
