import Cookies from 'js-cookie'
import { useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { ProviderAccountsHeader } from './accounts-header'
import { AddNewGroupModal } from '../modals/add-new-group-modal'
import { Groups } from './groups'

import styles from './index.module.css'

export function ProviderAccount() {
  const loggedIn = Cookies.get('piecekeeper-logged-in')

  const location = useLocation()

  if (!loggedIn) return <Redirect to={`/sign-in?redirectUrl=${location.pathname}`} />

  const [showAddNewGroupModal, setShowAddNewGroupModal] = useState(false)
  const [promoterGroups, setPromoterGroups] = useState([])

  return (
    <div className={styles.providerAccountsWrapper}>
      <div className={styles.providerAccountsContainer}>
        <ProviderAccountsHeader />
        <Groups
          promoterGroups={promoterGroups}
          setPromoterGroups={setPromoterGroups}
          setShowAddNewGroupModal={setShowAddNewGroupModal}
        />
        <AddNewGroupModal
          promoterGroups={promoterGroups}
          setPromoterGroups={setPromoterGroups}
          setShowAddNewGroupModal={setShowAddNewGroupModal}
          showAddNewGroupModal={showAddNewGroupModal}
        />
      </div>
    </div>
  )
}
