import styles from './index.module.css'


export function DocumentsHeader() {
  return (
    <div className={styles.container}>
      <h1 className={styles.documentsHeaderTitle}>Documents</h1>
      <div className={styles.underline} />
    </div>
  )
}
