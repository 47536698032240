import PropTypes from 'prop-types'

import constants from 'src/constants'
import { ReactComponent as CloudUploadIcon } from 'src/images/cloud-upload-icon.svg'
import { returnFileSize } from 'src/utils/uploads'

import styles from './index.module.css'

export const ImageNeeded = (props) => {
  const { defaultImageInputRef, defaultImageCategory, setSelectedFile, setUploadDefaultImageModalIsOpen } = props

  const handleFileChange = (event) => {
    event.preventDefault()
    setUploadDefaultImageModalIsOpen(true)

    const file = event.target.files[0]
    const fileSize = returnFileSize(file.size)
    const fileSizeLimit = returnFileSize(constants.fileSizeLimit)
    file.fileSize = fileSize
    file.documentType = defaultImageCategory

    if (file.size > constants.fileSizeLimit) {
      window.alert(`Your file is ${fileSize}. Please select an image that is less than ${fileSizeLimit}`)
      return
    }

    setSelectedFile({ details: event.target.files[0], file: URL.createObjectURL(event.target.files[0]) })
    defaultImageInputRef.current = {
      details: event.target.files[0],
      documentType: defaultImageCategory,
      file: event.target.files[0],
    }
  }

  return (
    <div className={styles.imageContainer}>
      <CloudUploadIcon />
      <p>Image is missing</p>
      <div className={styles.labelHackContainer}>
        <label className={styles.fileUploadHackLabel}>
          Choose a file
          <input
            className={styles.customFileInput}
            onChange={handleFileChange}
            ref={defaultImageInputRef}
            type="file"
          />
        </label>
      </div>
    </div>
  )
}

ImageNeeded.propTypes = {
  defaultImageCategory: PropTypes.string,
  defaultImageInputRef: PropTypes.object,
  setSelectedFile: PropTypes.func,
  setUploadDefaultImageModalIsOpen: PropTypes.func,
}
