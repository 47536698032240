import PropTypes from 'prop-types'

import styles from './index.module.css'

export function DocumentsHeader(props) {
  return (
    <div className={styles.container}>
      <h1 className={styles.documentsHeaderTitle}>Documents</h1>
      <button
        className={styles.button}
        onClick={() => props.setUploadPdfModal({ isOpen: true, providerPdfTemplateId: null })}
      >
        Create New
      </button>
      <div className={styles.underline} />
    </div>
  )
}

DocumentsHeader.propTypes = {
  setUploadPdfModal: PropTypes.func,
}
