import * as R from 'ramda'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { v4 as uuidV4 } from 'uuid'

import { getS3SignedUrl, updatePromoterPdfTemplate, uploadImageToS3 } from 'src/pages/promoter/commands'
import constants from 'src/constants'
import { returnFileSize } from 'src/utils/uploads'

import * as materialStyles from './styles'
import styles from './upload-image-modal.module.css'

const useStyles = makeStyles({
  imageInput: materialStyles.imageUploadInput,
})

export function UploadImageModal(props) {
  const { imageToEdit, images, setImageEditorDrawerOpen, setImageToEdit, setImages, setUploadImageModalOpen } = props

  const [selectedFile, setSelectedFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [isUploading, setIsUploading] = useState(false)

  const uploadPdfStyles = useStyles()

  const handleImageNameChange = (event) => {
    setFileName(event.target.value)
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    const fileSize = returnFileSize(file.size)
    file.fileSize = fileSize
    file.userFileName = fileName

    if (file.size > constants.fileSizeLimit) {
      window.alert(`Your file is ${fileSize}. Please select an image that is less than 20MB`)
      return
    }

    setSelectedFile(file)
  }

  const fileData = () => {
    if (selectedFile) {
      return (
        <div>
          <p>File Name: {fileName || selectedFile.name}</p>
          <p>File Location: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>
          <p>File Size: {selectedFile.fileSize}</p>
        </div>
      )
    }
  }

  const handleCreateImage = async () => {
    if (!fileName) {
      window.alert('Please add a Image Name')
      return
    }

    if (!selectedFile) {
      window.alert('No file was selected, so no image was created')
      return
    }

    setIsUploading(true)

    const { s3Location, signedUrl } = await getS3SignedUrl({
      file: selectedFile,
    })

    let uploadResult = await uploadImageToS3({ file: selectedFile, signedUrl })

    if (uploadResult.status === 200) {
      const newImage = {
        filename: selectedFile.name,
        id: uuidV4(),
        s3Location,
      }

      try {
        await axios.post('/api/images/image/save', newImage)
      } catch (error) {
        console.error('Could not save new image', error)
      }

      const targetImage = R.isNil(imageToEdit)
        ? R.find(R.propEq('id', constants.defaultProviderImageId), images)
        : imageToEdit

      const updatedImages = R.map((image) => {
        if (image.id === imageToEdit?.id) {
          const newType = image.type.includes('Custom') ? image.type : targetImage.type.concat(' Custom')

          return { ...image, id: newImage.id, s3Location, type: newType }
        }

        return image
      })(images)

      await updatePromoterPdfTemplate({
        promoterPdfTemplateId: props.promoterPdfTemplateId,
        promoterPdfTemplateUpdates: { images: updatedImages },
      })

      setImages(updatedImages)
    }

    setImageToEdit(null)
    setUploadImageModalOpen(false)
    setIsUploading(false)
    setImageEditorDrawerOpen(false)
  }

  return (
    <div className={styles.uploadPdfModalContainer}>
      <div className={styles.modalHeaderContainer}>
        <p>New Images</p>
        <IconButton onClick={() => setUploadImageModalOpen(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.inputsContainer}>
        {isUploading ? (
          <div className={styles.circularProgress}>
            <CircularProgress />
          </div>
        ) : null}
        <TextField classes={{ root: uploadPdfStyles.imageInput }} label="Image Name" onChange={handleImageNameChange} />
        <p>Upload your image</p>
        <p>Accepted file types:</p>
        <p>PDF</p>
        <div className={styles.uploadContainer}>
          <div className={styles.labelHackContainer}>
            <label className={styles.fileUploadHackLabel}>
              Choose a file
              <input className={styles.customFileInput} onChange={handleFileChange} type="file" />
            </label>
          </div>
          {fileData()}
        </div>
        <div className={styles.createImageButton}>
          <button onClick={handleCreateImage}>Create image</button>
        </div>
      </div>
    </div>
  )
}

UploadImageModal.propTypes = {
  imageToEdit: PropTypes.object,
  images: PropTypes.array,
  promoterPdfTemplateId: PropTypes.string,
  setImageEditorDrawerOpen: PropTypes.func,
  setImageToEdit: PropTypes.func,
  setImages: PropTypes.func,
  setUpdatePage: PropTypes.func,
  setUploadImageModalOpen: PropTypes.func,
  texts: PropTypes.array,
  updatePage: PropTypes.bool,
}
