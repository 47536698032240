import axios from 'axios'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { useEffect, useState } from 'react'

import styles from './share.module.css'

export const Share = (props) => {
  const { providerPdfTemplateId } = props
  const [groupList, setGroupList] = useState([])
  const [timerSecondsLeft, setTimeSecondsLeft] = useState(0)

  useEffect(() => {
    async function getGroupsData() {
      try {
        const { data } = await axios.get(`/api/provider/template/${providerPdfTemplateId}/groups`)

        setGroupList(data)
      } catch (error) {
        console.log('Could not get promoters information!', error)
      }
    }

    getGroupsData()
  }, [])

  useEffect(() => {
    const milliseconds = timerSecondsLeft * 1000
    if (timerSecondsLeft > 0) {
      setTimeout(() => {
        setTimeSecondsLeft(0)
      }, milliseconds)
    }
  }, [timerSecondsLeft])

  const handleToggle = (event, group) => {
    const updateGroup = (group) => {
      return { ...group, documentInGroup: !group.documentInGroup }
    }

    const updatedGroups = R.adjust(R.findIndex(R.propEq('groupId', group.groupId), groupList), updateGroup, groupList)

    setGroupList(updatedGroups)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    setTimeSecondsLeft(4)

    const templateGroupIds = R.compose(
      R.pluck('groupId'),
      R.filter((group) => group.documentInGroup)
    )(groupList)

    try {
      await axios.post(`/api/provider/template/${providerPdfTemplateId}/groups`, templateGroupIds)
    } catch (error) {
      console.log('Problem updating promoters groups.', error)
    }
  }

  return (
    <div className={styles.shareContainer}>
      <div className={styles.groupsHeaderContainer}>
        <h3>Groups</h3>
        <p>
          After updating the groups, promoters in the selected groups will receive access to this document. Promoters
          that are not in any of the selected groups will not have access to this document.
        </p>
      </div>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit}>
          {groupList.map((group) => {
            return (
              <div className={styles.inputContainer} key={group.groupId}>
                <input
                  type="checkbox"
                  checked={group.documentInGroup}
                  className={styles.checkbox}
                  onChange={(e) => e.preventDefault}
                  value={group.groupId}
                />
                <span className={styles.checkmark} onClick={(e) => handleToggle(e, group)}></span>
                <label className={styles.label}>{group.displayName}</label>
              </div>
            )
          })}
          <div className={styles.submitContainer}>
            <button className={styles.submitButton} type="submit">
              Update
            </button>
          </div>
        </form>
      </div>
      {timerSecondsLeft > 0 ? (
        <div>
          <p>Groups successfully updated!</p>
        </div>
      ) : null}
    </div>
  )
}

Share.propTypes = {
  providerPdfTemplateId: PropTypes.string,
}
