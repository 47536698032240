export default {
  defaultImageCategories: ['logo', 'headshot'],
  defaultProviderImageId: '627e02c8-5f6f-40cb-920a-ce625c6c7d93',
  fileSizeLimit: 20000000,
  groups: {
    everyone: {
      displayName: 'Everyone',
      id: '87a7c23c-1a57-4f29-bbfa-8641d1ab1a8b',
    },
  },
  minimumPasswordLength: 12,
  routes: {
    admin: {
      createProviderUser: '/api/admin/create-provider-user',
      deleteProviderWithNoPromoters: '/api/admin/users/providers',
      impersonate: '/api/admin/impersonate',
      resetUserMetrics: '/api/admin/reset-user-metrics',
      setPassword: '/api/admin/set-password',
    },
    changePassword: '/api/change-password',
    promoter: {
      getDefaultInformation: '/api/promoter/information/default',
      getPdfTemplates: '/api/promoter/assigned-pdfs',
      saveInfo: '/api/promoter/promoter-info/save',
    },
    provider: {
      createPromoterUser: '/api/provider/create-promoter-user',
      createProviderPdfTemplate: '/api/provider/template',
      deleteProviderPdfTemplate: '/api/provider/template',
      getProviderPdfTemplates: '/api/provider/provider-pdf-templates',
      getSignedToken: '/api/provider/provider-pdfs/upload',
      invitePromoterUser: '/api/provider/invite-promoter',
      providerPdfs: '/api/provider/provider-pdfs',
      savePdf: '/api/provider/provider-pdfs/save',
    },
    signIn: '/api/sign-in',
    signOut: '/api/sign-out',
    signUp: {
      promoter: '/api/sign-up/promoter',
    },
    user: '/api/users',
  },
  textLabelToTypeMapping: {
    'Custom Text': 'customText',
    Email: 'email',
    'First Name': 'firstName',
    'Full Name': 'fullName',
    'Last Name': 'lastName',
    'Phone Number': 'phoneNumber',
  },
  textTypeToLabelMapping: {
    customText: 'Custom Text',
    email: 'Email',
    firstName: 'First Name',
    fullName: 'Full Name',
    lastName: 'Last Name',
    phoneNumber: 'Phone Number',
  },
}
