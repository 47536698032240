import axios from 'axios'
import Cookies from 'js-cookie'
import { Redirect, useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'

import { ChangePassword } from 'src/pages/shared/change-password'
import constants from 'src/constants'

import { ContactInformation } from './contact-information'
import { DefaultPhotos } from './default-photos'
import { PromoterAccountsHeader } from './accounts-header'
import { UploadDefaultImageModal } from './modals/upload-default-image-modal'

import styles from './index.module.css'

export function PromoterAccount() {
  const [defaultUserImages, setDefaultUserImages] = useState([])
  const [defaultUserTexts, setDefaultUserTexts] = useState([])
  const [selectedFile, setSelectedFile] = useState({})
  const [uploadDefaultImageModalIsOpen, setUploadDefaultImageModalIsOpen] = useState(false)
  const [userSelectedDefaultPhoto, setUserSelectedDefaultPhoto] = useState({})

  const defaultImageInputRef = useRef()

  useEffect(() => {
    async function getUsersDefaultImages() {
      try {
        const { data } = await axios.get(constants.routes.promoter.getDefaultInformation)

        setDefaultUserImages(data.images)
        setDefaultUserTexts(data.texts)
      } catch (error) {
        console.log('Could not get default photos!', error)
      }
    }

    getUsersDefaultImages()
  }, [uploadDefaultImageModalIsOpen])

  const loggedIn = Cookies.get('piecekeeper-logged-in')

  const location = useLocation()

  if (!loggedIn) return <Redirect to={`/sign-in?redirectUrl=${location.pathname}`} />

  return (
    <div className={styles.promoterAccountsContainer}>
      <UploadDefaultImageModal
        defaultUserImages={defaultUserImages}
        defaultImageInputRef={defaultImageInputRef}
        uploadDefaultImageModalIsOpen={uploadDefaultImageModalIsOpen}
        selectedFile={selectedFile}
        setDefaultUserImages={setDefaultUserImages}
        setUploadDefaultImageModalIsOpen={setUploadDefaultImageModalIsOpen}
        setSelectedFile={setSelectedFile}
        setUserSelectedDefaultPhoto={setUserSelectedDefaultPhoto}
        userSelectedDefaultPhoto={userSelectedDefaultPhoto}
      />
      <PromoterAccountsHeader />
      <div className={styles.promoterAccountsInfoContainer}>
        <DefaultPhotos
          defaultImageInputRef={defaultImageInputRef}
          defaultUserImages={defaultUserImages}
          setUploadDefaultImageModalIsOpen={setUploadDefaultImageModalIsOpen}
          setSelectedFile={setSelectedFile}
          setUserSelectedDefaultPhoto={setUserSelectedDefaultPhoto}
          userSelectedDefaultPhoto={userSelectedDefaultPhoto}
        />
        <div className={styles.contentRightContainer}>
          <ContactInformation defaultUserTexts={defaultUserTexts} />
          <ChangePassword />
        </div>
      </div>
    </div>
  )
}
