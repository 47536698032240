import { useState } from 'react'
import { Link } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import './index.css'
import styles from './index.module.css'

function a11yProps(index) {
  return {
    'aria-controls': `nav-tabpanel-${index}`,
    id: `nav-tab-${index}`,
  }
}

function LinkTab(props) {
  return <Tab component={Link} {...props} />
}

export const HeaderTabs = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Tabs
        aria-label="Header Nav"
        className={styles.headerTabs}
        indicatorColor="primary"
        onChange={handleChange}
        value={value}
      >
        <LinkTab label="Documents" to="/provider/documents" {...a11yProps(0)} />
        <LinkTab label="Promoters" to="/provider/promoters" {...a11yProps(1)} />
        <LinkTab label="Account" to="/provider/account" {...a11yProps(2)} />
      </Tabs>
    </div>
  )
}
