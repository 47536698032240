import Cookies from 'js-cookie'
import Modal from '@material-ui/core/Modal'
import { useRef, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { DocumentsHeader } from './documents-header'
import { DocumentsDisplay } from './documents-display'
import { RenameDocumentModal } from './modals'
import { UploadPdfModal } from './modals'

import styles from './index.module.css'

export function ProviderDocuments() {
  const loggedIn = Cookies.get('piecekeeper-logged-in')

  const location = useLocation()

  if (!loggedIn) return <Redirect to={`/sign-in?redirectUrl=${location.pathname}`} />

  const [uploadPdfModal, setUploadPdfModal] = useState({ isOpen: false, providerPdfTemplateId: null })
  const [renameDocumentModal, setRenameDocumentModal] = useState({ isOpen: false, pdfId: null })
  const documentHeaderRef = useRef(null)

  return (
    <div className={styles.documentsContainer} ref={documentHeaderRef}>
      <DocumentsHeader documentHeaderRef={documentHeaderRef} setUploadPdfModal={setUploadPdfModal} />
      <DocumentsDisplay
        renameDocumentModalIsOpen={renameDocumentModal}
        setRenameDocumentModal={setRenameDocumentModal}
        setUploadPdfModal={setUploadPdfModal}
      />
      <Modal open={uploadPdfModal.isOpen}>
        <div>
          <UploadPdfModal setUploadPdfModal={setUploadPdfModal} uploadPdfModal={uploadPdfModal} />
        </div>
      </Modal>
      <RenameDocumentModal renameDocumentModal={renameDocumentModal} setRenameDocumentModal={setRenameDocumentModal} />
    </div>
  )
}
