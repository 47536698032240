import axios from 'axios'
import Cookies from 'js-cookie'
import * as R from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import { PdfEditor } from '../pdf-editor'

export function PromoterDocumentDetails() {
  const [images, setImages] = useState([])
  const [texts, setTexts] = useState([])
  const [s3PdfLocation, setS3PdfLocation] = useState(null)
  const [promoterPdfLocation, setPromoterPdfLocation] = useState(null)
  const [updatePage, setUpdatePage] = useState(false)

  const { promoterTemplateId } = useParams()

  useEffect(() => {
    async function fetchPromoterData() {
      try {
        const response = await axios.get(`/api/promoter/promoter-pdf-template/${promoterTemplateId}`)
        const appUrl = `${window.origin}`.concat(R.pathOr(null, ['promoterPdf', 'appUrl'], response.data))

        setImages(response.data.images)
        setTexts(response.data.texts)
        setS3PdfLocation(response.data.providerPdf.s3Location)
        setPromoterPdfLocation(appUrl)
      } catch (error) {
        console.log(error)
      }
    }

    fetchPromoterData()
  }, [])

  const loggedIn = Cookies.get('piecekeeper-logged-in')
  if (!loggedIn) return <Redirect to="/sign-in?redirectUrl=/promoter" />

  const rootRef = useRef(null)

  return (
    <div ref={rootRef}>
      <PdfEditor
        images={images}
        updatePage={updatePage}
        rootRef={rootRef}
        s3PdfLocation={s3PdfLocation}
        promoterPdfLocation={promoterPdfLocation}
        setPromoterPdfLocation={setPromoterPdfLocation}
        setImages={setImages}
        setTexts={setTexts}
        setUpdatePage={setUpdatePage}
        texts={texts}
      />
    </div>
  )
}
