import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as CopyIcon } from 'src/images/copy-icon.svg'
import { ReactComponent as UpdateIcon } from 'src/images/update-icon.svg'

import styles from './top-menu-bar.module.css'

import * as materialStyles from './styles'

const useStyles = makeStyles({
  buttons: materialStyles.buttons,
})

export const TopMenuBar = (props) => {
  const { promoterPdfTemplateId, promoterPdfLocation, setCreatingDocumentModalOpen, setPromoterPdfLocation } = props

  const [value, setValue] = useState('')
  const [copied, setCopied] = useState(false)
  const [timerSecondsLeft, setTimeSecondsLeft] = useState(0)

  useEffect(() => {
    setValue(promoterPdfLocation)
  }, [promoterPdfLocation])

  useEffect(() => {
    const milliseconds = timerSecondsLeft * 1000

    if (timerSecondsLeft > 0) {
      setTimeout(() => {
        setTimeSecondsLeft(0)
        setCopied(false)
      }, milliseconds)
    }
  }, [timerSecondsLeft])

  const inputStyles = useStyles()
  const history = useHistory()

  const handleOnDownload = async () => {
    setCreatingDocumentModalOpen({ isLoading: true, isOpen: true })

    try {
      const { data: appUrl } = await axios.post(`/api/promoter/document/${promoterPdfTemplateId}/download`)

      const promoterPdfLocation = `${window.origin}${appUrl}`

      setCreatingDocumentModalOpen({ isLoading: false, isOpen: true })
      setPromoterPdfLocation(promoterPdfLocation)
    } catch (error) {
      console.log('There was an issue downloading the promoter pdf', error)
    }
  }

  return (
    <div className={styles.topMenuBar}>
      <div className={styles.companyOverviewNav}>
        <ArrowBackIcon
          className={styles.topMenuBarBackButton}
          onClick={() => history.goBack()}
          style={{ fontSize: '15px' }}
        />
        <h2 className={styles.topMeuBarBackLabel}>Company Overview</h2>
      </div>
      <div className={styles.actionContainer}>
        <Button
          classes={{ root: inputStyles.buttons }}
          color="default"
          onClick={handleOnDownload}
          startIcon={<UpdateIcon className={styles.actionIcons} />}
          variant="text"
        >
          Update Document With Changes
        </Button>
        {promoterPdfLocation ? (
          <div className={styles.shareableLinkButtonContainer}>
            <div className={styles.shareableLink}>
              <p>{promoterPdfLocation}</p>
            </div>
            <CopyToClipboard
              text={value}
              onCopy={() => {
                setTimeSecondsLeft(2)
                setCopied(true)
              }}
            >
              <button className={styles.shareableLinkButton}>
                <CopyIcon className={styles.copyIcon} />
                Share
              </button>
            </CopyToClipboard>
            {copied && timerSecondsLeft > 0 ? (
              <div className={styles.copiedNotification}>
                <p>Copied</p>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}

TopMenuBar.propTypes = {
  promoterPdfLocation: PropTypes.string,
  promoterPdfTemplateId: PropTypes.string,
  setCreatingDocumentModalOpen: PropTypes.func,
  setPromoterPdfLocation: PropTypes.func,
}
