import axios from 'axios'
import IconButton from '@material-ui/core/IconButton'
import { Link, useParams } from 'react-router-dom'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PropTypes from 'prop-types'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import constants from 'src/constants'
import { ReactComponent as PieceKeeperLogoSvg } from 'src/images/piece-keeper-logo.svg'
import { ReactComponent as EditPencilIcon } from 'src/images/error.svg'

import styles from './index.module.css'
import { isValidEmail } from 'src/utils/validate-email'

const LoginFeedback = ({ isFailed, type }) => {
  return (
    <div
      className={
        isFailed
          ? `${styles.loginFeedbackContainer} ${styles.loginFailedFeedbackContainer}`
          : styles.loginFeedbackContainer
      }
    >
      {isFailed ? (
        <>
          <EditPencilIcon className={styles.errorIcon} />
          {
            {
              genericFailure: <p className={styles.loginFeedback}>Failed to create user. Please try again</p>,
              invalidEmail: <p className={styles.loginFeedback}>Please enter a valid email address</p>,
              invalidPasswordLength: (
                <p className={styles.loginFeedback}>Please enter a password length of at least 12</p>
              ),
            }[type]
          }
        </>
      ) : null}
    </div>
  )
}

LoginFeedback.propTypes = {
  isFailed: PropTypes.bool,
  type: PropTypes.string,
}

export function SignUp() {
  const [pendingUser, setPendingUser] = useState({ loading: true })

  const history = useHistory()
  const { pendingUserId } = useParams()

  const [focus, setFocus] = useState({ email: false, password: false })
  const [seePassword, setSeePassword] = useState(false)
  const [failedSignUp, setFailedSignUp] = useState({ isFailed: false })

  const { register, reset, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  useEffect(() => {
    const getPromoterPendingUser = async () => {
      const endpoint = constants.routes.signUp.promoter + `/${pendingUserId}`

      try {
        const { data: pendingUser } = await axios.get(endpoint)

        reset({
          email: pendingUser.email,
          password: '',
        })

        setPendingUser(pendingUser)
      } catch (err) {
        if (err.response.status === 404) history.push('/sign-in')
      }
    }

    getPromoterPendingUser()
  }, [])

  const focusStyle = {
    container: {
      borderBottom: '2px solid #1f8289',
      color: '#1f8289',
    },
    label: {
      color: '#1f8289',
    },
  }

  const onSubmit = async (data) => {
    if (!isValidEmail(data.email)) {
      return setFailedSignUp({ isFailed: true, type: 'invalidEmail' })
    }

    if (data.password.length < constants.minimumPasswordLength) {
      return setFailedSignUp({ isFailed: true, type: 'invalidPasswordLength' })
    }

    try {
      const endpoint = constants.routes.signUp.promoter + `/${pendingUserId}`

      await axios.post(endpoint, {
        email: data.email,
        password: data.password,
      })
    } catch (error) {
      return setFailedSignUp({ isFailed: true, type: 'genericFailure' })
    }

    history.push('/promoter/account')
  }

  const handleShowPassword = () => {
    setSeePassword(!seePassword)
  }

  const handleFocus = (input) => {
    setFocus({ ...focus, [input]: true })
  }

  const handleBlur = (input) => {
    setFocus({ ...focus, [input]: false })
  }

  if (pendingUser.loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
        <p>Loading...</p>
      </div>
    )
  }

  return (
    <div className={styles.signUpPage}>
      <div className={styles.signUpBox}>
        <div className={styles.logoContainer}>
          <PieceKeeperLogoSvg lassName={styles.pieceKeeperLogo} />
        </div>
        <div className={styles.signUpIntroduction}>
          {`${pendingUser.companyDisplayName} has invited you to access their co-branded materials.
          Create your account to get started.`}
        </div>
        <div className={styles.signUpContainer}>
          <LoginFeedback isFailed={failedSignUp.isFailed} type={failedSignUp.type} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.userInputsContainer}>
              <div className={styles.userInput} style={focus.email ? focusStyle.container : null}>
                <MailOutlineIcon className={styles.iconLeft} />
                <div className={styles.labelAndInput}>
                  <label style={focus.email ? focusStyle.label : null}>Email Address</label>
                  <input
                    name="email"
                    onBlur={() => handleBlur('email')}
                    onFocus={() => handleFocus('email')}
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              <div className={styles.userInput} style={focus.password ? focusStyle.container : null}>
                <LockOutlinedIcon className={styles.iconLeft} />
                <div className={styles.labelAndInput}>
                  <label style={focus.password ? focusStyle.label : null}>Password</label>
                  <input
                    name="password"
                    onBlur={() => handleBlur('password')}
                    onFocus={() => handleFocus('password')}
                    ref={register({ required: true })}
                    type={seePassword ? 'text' : 'password'}
                  />
                </div>
                <IconButton
                  className={styles.iconButton}
                  aria-label="toggle password visibility"
                  onClick={handleShowPassword}
                >
                  {seePassword ? (
                    <VisibilityOff className={styles.iconRight} />
                  ) : (
                    <Visibility className={styles.iconRight} />
                  )}
                </IconButton>
              </div>
            </div>
            <button className={styles.button} type="submit" variant="contained">
              Create Account
            </button>
          </form>
          <div className={styles.signUpPrompts}>
            <div>Already have an account?</div>
            <Link to="/sign-in">Sign In</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
