import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

import constants from 'src/constants'
import { replacePdf } from 'src/pages/provider/pdf-editor/update-provider-pdf-template-commands/replace-pdf'

import { returnFileSize } from 'src/utils/uploads'

import * as materialStyles from './styles'
import styles from './index.module.css'

const useStyles = makeStyles({
  documentInput: materialStyles.documentUploadInput,
})

export function UploadPdfModal(props) {
  const { setUploadPdfModal, uploadPdfModal } = props

  const [selectedFile, setSelectedFile] = useState(null)
  const history = useHistory()
  const [filename, setFilename] = useState('')

  const uploadPdfStyles = useStyles()

  const handleDocumentNameChange = (event) => {
    setFilename(event.target.value)
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    const fileSize = returnFileSize(file.size)
    file.fileSize = fileSize
    file.documentName = filename

    if (!file.type.includes('pdf')) {
      window.alert('You must select a pdf')
      return
    }

    if (file.size > constants.fileSizeLimit) {
      window.alert(`Your file is ${fileSize}. Please select a pdf that is less than 20MB`)
      return
    }

    setSelectedFile(file)
  }

  const fileData = () => {
    if (selectedFile) {
      return (
        <div>
          <p>File Name: {filename || selectedFile.name}</p>
          <p>File Location: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>
          <p>File Size: {selectedFile.fileSize}</p>
        </div>
      )
    }
  }

  const handleCreateDocument = async () => {
    if (!filename) {
      window.alert('Please add a Document Name')
      return
    }

    if (!selectedFile) {
      window.alert('No file was selected, so no document was created')
      return
    }

    const signedTokenRoute = constants.routes.provider.getSignedToken

    const response = await axios.get(`${signedTokenRoute}?filename=${selectedFile.name}&fileType=${selectedFile.type}`)

    const { s3Url, signedUrl } = response.data

    try {
      await axios.put(signedUrl, selectedFile)

      const pdfSaveRoute = constants.routes.provider.savePdf

      const { data: providerPdf } = await axios.post(pdfSaveRoute, {
        filename,
        s3Location: s3Url,
      })

      if (!uploadPdfModal.providerPdfTemplateId) {
        const createProviderPdfTemplateRoute = constants.routes.provider.createProviderPdfTemplate

        const { data: providerPdfTemplate } = await axios.post(createProviderPdfTemplateRoute, {
          providerPdfId: providerPdf.id,
        })

        history.push(`/provider/documents/${providerPdfTemplate.id}`)
      } else {
        await replacePdf({
          id: uploadPdfModal.providerPdfTemplateId,
          providerPdfId: providerPdf.id,
        })

        history.push(`/provider/documents/${uploadPdfModal.providerPdfTemplateId}`)
      }
    } catch (error) {
      console.log('ERROR for S3 upload or database save 😞', error.response)
    }
  }

  return (
    <div className={styles.uploadPdfModalContainer}>
      <div className={styles.modalHeaderContainer}>
        <p>New Documents</p>
        <IconButton
          onClick={() => {
            setUploadPdfModal({ isOpen: false, providerPdfTemplateId: null })
            setSelectedFile(null)
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.inputsContainer}>
        <TextField
          classes={{ root: uploadPdfStyles.documentInput }}
          label="Document Name"
          onChange={handleDocumentNameChange}
        />
        <p>Upload your document</p>
        <p>Accepted file types:</p>
        <p>PDF</p>
        <div className={styles.uploadContainer}>
          <div className={styles.labelHackContainer}>
            <label className={styles.fileUploadHackLabel}>
              Choose a file
              <input className={styles.customFileInput} onChange={handleFileChange} type="file" />
            </label>
          </div>
          {fileData()}
        </div>
        <div className={styles.createDocumentButton}>
          <button onClick={handleCreateDocument}>
            {props.uploadPdfModal.providerPdfTemplateId ? 'Replace' : 'Create'} document
          </button>
        </div>
      </div>
    </div>
  )
}

UploadPdfModal.propTypes = {
  setUploadPdfModal: PropTypes.func,
  uploadPdfModal: PropTypes.object,
}
