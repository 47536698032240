import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Rnd } from 'react-rnd'
import { v4 as uuidv4 } from 'uuid'

import * as updateProviderPdfTemplateCommands from './update-provider-pdf-template-commands'
import { DraggableText } from './draggable-text'
import { Pdf } from './pdf'
import { RightSidebar } from './right-sidebar'
import { TextComponent } from './text-component'
import { TopMenuBar } from './top-menu-bar'

import * as materialStyles from './styles'
import styles from './index.module.css'

const TOP_MARGIN = 20

const usePdfEditorStyles = makeStyles({
  imageIcon: materialStyles.addImageIcon,
})

function buildInitialImages(images, pdfEditorStyles) {
  const mapIndexed = R.addIndex(R.map)

  return mapIndexed(
    (image, index) => ({
      component: (
        <div className={styles.imageContainer}>
          <div className={styles.imageLabelNumber}>{index + 1}</div>
          <AddPhotoAlternateOutlinedIcon classes={{ root: pdfEditorStyles.imageIcon }} />
        </div>
      ),
      id: image.id,
      label: image.label,
      location: image.location,
      type: image.type,
    }),
    images
  )
}

function buildInitialTexts(texts) {
  const mapIndexed = R.addIndex(R.map)

  return mapIndexed(
    (text, index) => ({
      component: <TextComponent newCount={index + 1} text={text} />,
      count: index + 1,
      expanded: false,
      id: text.id,
      label: text.label,
      location: text.location,
      styleOptions: text.styleOptions,
      textField: text.textField,
      type: text.type,
    }),
    texts
  )
}

export function PdfEditor(props) {
  const { providerPdf, setProviderPdf, setShowUploadPdfModal } = props

  const [currentFocus, setCurrentFocus] = useState({ id: null, type: null })

  const [itemCount, setItemCount] = useState({ images: 0, texts: 0 })
  const [images, setImages] = useState([])
  const [texts, setTexts] = useState([])

  const [pdfLocation, setPdfLocation] = useState({})
  const [pdfPageNumber, setPdfPageNumber] = useState(1)
  const [numberOfPdfPages, setNumPages] = useState(null)

  const [scalingFactor, setScalingFactor] = useState(null)
  const pdfEditorRef = useRef()

  const pdfEditorStyles = usePdfEditorStyles()

  const { providerPdfTemplateId } = useParams()

  useEffect(() => {
    async function getProviderPdfTemplate() {
      const endpoint = `/api/provider/template/${providerPdfTemplateId}`

      if (!providerPdf) {
        const response = await axios.get(endpoint)

        const { providerPdfId, providerPdfS3Location, providerPdfDocumentName, images, texts } = response.data

        setProviderPdf({
          documentName: providerPdfDocumentName,
          id: providerPdfId,
          images: images,
          s3Location: providerPdfS3Location,
          texts: texts,
        })
      }

      if (pdfLocation && scalingFactor && providerPdf) {
        const initialImages = buildInitialImages(providerPdf.images, pdfEditorStyles)
        const initialTexts = buildInitialTexts(providerPdf.texts)

        setImages(initialImages)
        setTexts(initialTexts)

        setItemCount({ images: initialImages.length, texts: initialTexts.length })
      }
    }

    getProviderPdfTemplate()
  }, [pdfLocation, scalingFactor])

  const handleImageResizeStop = async ({ currentImage, images, ref, position }) => {
    const width = parseInt(ref.style.width)
    const height = parseInt(ref.style.height)

    const newImage = {
      ...currentImage,
      location: {
        ...currentImage.location,
        height,
        pageX: position.x + window.scrollX,
        pageY: position.y + ref.parentElement.offsetTop,
        width,
        xStart: (position.x + window.scrollX - pdfLocation.x) / scalingFactor.width,
        yStart: (position.y + ref.parentElement.offsetTop - pdfLocation.y) / scalingFactor.height,
      },
    }

    const newImages = R.map((image) => {
      return image.id === newImage.id ? newImage : image
    }, images)

    setImages(newImages)

    await updateProviderPdfTemplateCommands.saveTemplate({ id: providerPdfTemplateId, images: newImages })
  }

  const handleImageDragStop = async ({ currentImage, images }, draggableData) => {
    const newImage = {
      ...currentImage,
      location: {
        ...currentImage.location,
        pageX: draggableData.x + window.scrollX,
        pageY: draggableData.y + draggableData.node.parentElement.offsetTop,
        xStart: (draggableData.x + window.scrollX - pdfLocation.x) / scalingFactor.width,
        yStart: (draggableData.y + draggableData.node.parentElement.offsetTop - pdfLocation.y) / scalingFactor.height,
      },
    }

    const newImages = R.map((image) => {
      return image.id === newImage.id ? newImage : image
    }, images)

    setImages(newImages)

    await updateProviderPdfTemplateCommands.saveTemplate({ id: providerPdfTemplateId, images: newImages })
  }

  const deleteImageHandler = async (imageId) => {
    const indexToDelete = R.findIndex(R.propEq('id', imageId))(images)

    const newImages = R.remove(indexToDelete, 1, images)

    setImages(newImages)
    setCurrentFocus({})

    await updateProviderPdfTemplateCommands.saveTemplate({ id: providerPdfTemplateId, images: newImages })
  }

  const createImageHandler = async () => {
    const id = uuidv4()

    const newCount = ++itemCount.images
    setItemCount({ ...itemCount, images: newCount })

    const newImages = images.concat([
      {
        component: (
          <div className={styles.imageContainer}>
            <div className={styles.imageLabelNumber}>{newCount}</div>
            <AddPhotoAlternateOutlinedIcon classes={{ root: pdfEditorStyles.imageIcon }} />
          </div>
        ),
        id,
        label: `Image ${itemCount.images}`,
        location: { height: 62, width: 62, xStart: 0, yStart: 0 },
        type: 'headshot',
      },
    ])

    setCurrentFocus({ id, type: 'image' })
    setImages(newImages)

    await updateProviderPdfTemplateCommands.saveTemplate({ id: providerPdfTemplateId, images: newImages })
  }

  const handleDeleteText = async (textId) => {
    const indexToDelete = R.findIndex(R.propEq('id', textId))(texts)

    const newTexts = R.remove(indexToDelete, 1, texts)

    setCurrentFocus({})
    setTexts(newTexts)

    await updateProviderPdfTemplateCommands.saveTemplate({ id: providerPdfTemplateId, texts: newTexts })
  }

  const createTextHandler = async () => {
    const id = uuidv4()

    const newCount = ++itemCount.texts

    setItemCount({ ...itemCount, texts: newCount })

    const styleOptions = {
      color: '#000000',
      fontFamily: 'Courier',
      fontSize: 14,
      fontStyle: 'unset',
      fontWeight: 'unset',
      textAlign: 'left',
      textDecoration: 'none',
      width: '100% + 20px',
    }

    const newTexts = texts.concat([
      {
        component: <TextComponent newCount={newCount} text={{ styleOptions, textField: 'Choose a text field' }} />,
        count: newCount,
        expanded: true,
        id,
        label: `Text ${itemCount.texts}`,
        location: { width: 120, xStart: 0, yStart: 0 },
        styleOptions,
        textField: 'Choose a text field',
        type: 'unknown',
      },
    ])

    setCurrentFocus({ id, type: 'text' })
    setTexts(newTexts)

    await updateProviderPdfTemplateCommands.saveTemplate({ id: providerPdfTemplateId, texts: newTexts })
  }

  return (
    <>
      <TopMenuBar
        documentName={providerPdf?.documentName}
        images={images}
        numberOfPdfPages={numberOfPdfPages}
        pdfPageNumber={pdfPageNumber}
        providerPdfTemplateId={providerPdfTemplateId}
        scalingFactor={scalingFactor}
        setPdfPageNumber={setPdfPageNumber}
        setShowUploadPdfModal={setShowUploadPdfModal}
        texts={texts}
      />
      <div className={styles.editor}>
        <div className={styles.pdfEditor} ref={pdfEditorRef}>
          {images.map((image) => {
            return (
              <Rnd
                bounds={'.react-pdf__Page__canvas'}
                style={{ zIndex: 2 }}
                size={{ height: image.location.height, width: image.location.width }}
                key={image.id}
                default={{
                  x: pdfLocation.x + image.location.xStart * scalingFactor.width,
                  y: image.location.yStart * scalingFactor.height + TOP_MARGIN,
                }}
                onDragStart={() => setCurrentFocus({ id: image.id, type: 'image' })}
                onDragStop={(_, draggableData) => {
                  handleImageDragStop({ currentImage: image, images }, draggableData)
                }}
                onResizeStop={(_, __, ref, ___, position) => {
                  handleImageResizeStop({ currentImage: image, images, position, ref })
                }}
              >
                {image.component}
              </Rnd>
            )
          })}
          {texts.map((text, index) => (
            <DraggableText
              key={index}
              pdfLocation={pdfLocation}
              text={text}
              texts={texts}
              scalingFactor={scalingFactor}
              setCurrentFocus={setCurrentFocus}
              styles={styles}
              setTexts={setTexts}
            />
          ))}
          {providerPdf?.s3Location ? (
            <Pdf
              pdfS3Location={providerPdf.s3Location}
              pdfLocation={pdfLocation}
              setNumPages={setNumPages}
              pdfPageNumber={pdfPageNumber}
              setPdfLocation={setPdfLocation}
              setPdfPageNumber={setPdfPageNumber}
              setScalingFactor={setScalingFactor}
            />
          ) : null}
        </div>
        <RightSidebar
          createImageHandler={createImageHandler}
          createTextHandler={createTextHandler}
          currentFocus={currentFocus}
          deleteImageHandler={deleteImageHandler}
          fontList={props.fontList}
          handleDeleteText={handleDeleteText}
          images={images}
          providerPdfTemplateId={providerPdfTemplateId}
          setCurrentFocus={setCurrentFocus}
          setImages={setImages}
          setTexts={setTexts}
          texts={texts}
        />
      </div>
    </>
  )
}

PdfEditor.propTypes = {
  fontList: PropTypes.array,
  providerPdf: PropTypes.object,
  setProviderPdf: PropTypes.func,
  setShowUploadPdfModal: PropTypes.func,
  showUploadPdfModal: PropTypes.object,
}
