import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useEffect, useState } from 'react'

import { ReactComponent as LinkIcon } from 'src/images/link-icon.svg'
import { ReactComponent as CloseIcon } from 'src/images/close-icon.svg'

import styles from './creating-document-modal.module.css'

export const CreatingDocumentModal = (props) => {
  const { creatingDocumentModalOpen, setCreatingDocumentModalOpen, promoterPdfLocation } = props

  const [value, setValue] = useState('')
  const [copied, setCopied] = useState(false)
  const [timerSecondsLeft, setTimeSecondsLeft] = useState(0)

  useEffect(() => {
    setValue(promoterPdfLocation)
  }, [creatingDocumentModalOpen.isLoading])

  useEffect(() => {
    const milliseconds = timerSecondsLeft * 1000
    if (timerSecondsLeft > 0) {
      setTimeout(() => {
        setTimeSecondsLeft(0)
        setCopied(false)
      }, milliseconds)
    }
  }, [timerSecondsLeft])

  return creatingDocumentModalOpen.isOpen ? (
    <div className={styles.creatingDocumentModal}>
      <div className={styles.modalHeaderContainer}>
        {creatingDocumentModalOpen.isLoading ? <p>Updating Document</p> : <p>Document Updated</p>}
        <button
          className={styles.closeIcon}
          onClick={() => {
            setCreatingDocumentModalOpen({ isLoading: false, isOpen: false })
          }}
        >
          <CloseIcon />
        </button>
      </div>
      <div className={styles.contentContainer}>
        {creatingDocumentModalOpen.isLoading ? (
          <div className={styles.circularProgressContainer}>
            <CircularProgress size={60} />
          </div>
        ) : (
          <div className={styles.documentInfoContainer}>
            <div className={styles.updateText}>
              <p>Updates to your document were successfully made!</p>
              <p className={styles.everlinkText}>These updates will now show on existing links sent.</p>
            </div>
            <div className={styles.shareableLinkButtonContainer}>
              <div className={styles.copyButtonAndBubbleContainer}>
                <CopyToClipboard
                  text={value}
                  onCopy={() => {
                    setCopied(true)
                    setTimeSecondsLeft(2)
                  }}
                >
                  <button className={styles.shareableLinkButton}>
                    <LinkIcon className={styles.linkIcon} />
                    Copy your link
                  </button>
                </CopyToClipboard>
                {copied && timerSecondsLeft > 0 ? (
                  <div className={styles.copiedNotification}>
                    <p>Copied</p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null
}

CreatingDocumentModal.propTypes = {
  creatingDocumentModalOpen: PropTypes.object,
  promoterPdfLocation: PropTypes.string,
  setCreatingDocumentModalOpen: PropTypes.func,
}
