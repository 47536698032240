import Button from '@material-ui/core/Button'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'

import * as R from 'ramda'
import { makeStyles } from '@material-ui/core/styles'

import constants from 'src/constants'

import { handleStyleOptionsChange } from './text-field-form-commands'
import { TextComponent } from '../text-component'

import styles from './index.module.css'
import * as materialStyles from './styles'
import * as updateProviderPdfTemplateCommands from '../update-provider-pdf-template-commands'

const FONTS = ['Courier', 'Helvetica', 'Symbol', 'TimesRoman', 'ZapfDingbats']

const useInputStyles = makeStyles({
  deleteText: materialStyles.deleteText,
  halfInput: materialStyles.styleInput.halfInput,
  inputButtons: materialStyles.styleInput.inputButtons,
  inputButtonsContainer: materialStyles.styleInput.inputButtonsContainer,
  root: materialStyles.styleInput.root,
  withTopMargin: materialStyles.styleInput.withTopMargin,
})

export const TextFieldForm = (props) => {
  const { handleDeleteText, providerPdfTemplateId, setCurrentFocus, setTexts, textId, texts, text } = props

  const [textField, setTextField] = useState('Choose a text field')
  const [variantStyles, setVariantStyles] = useState({
    fontStyle: 'unset',
    fontWeight: 'unset',
    textDecoration: 'none',
  })
  const [styleOptions, setStyleOptions] = useState({
    color: '#000000',
    fontFamily: FONTS[0],
    fontSize: 14,
    textAlign: 'left',
    ...variantStyles,
  })

  const fontSizes = [8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96]

  const inputStyles = useInputStyles()

  useEffect(() => {
    const styleOptions = text.styleOptions

    setTextField(text.textField)
    setStyleOptions(styleOptions)
    setVariantStyles({
      fontStyle: styleOptions.fontStyle,
      fontWeight: styleOptions.fontWeight,
      textDecoration: styleOptions.textDecoration,
    })
  }, [])

  const handleTextFieldChange = async (event) => {
    const value = event.target.value
    const type = constants.textLabelToTypeMapping[value]

    setTextField(value)
    setCurrentFocus({ id: textId, type: 'text' })

    const id = textId
    const currentText = R.find(R.propEq('id', id), texts)
    const index = R.findIndex(R.propEq('id', id), texts)
    const count = currentText.count

    setStyleOptions(styleOptions)

    const component = (
      <TextComponent newCount={count} text={{ ...currentText, label: value, styleOptions, textField: value }} />
    )

    const newText = {
      ...currentText,
      component,
      label: value,
      textField: value,
      type,
    }

    const newTexts = R.update(index, newText, texts)

    setTexts(newTexts)

    await updateProviderPdfTemplateCommands.saveTemplate({ id: providerPdfTemplateId, texts: newTexts })
  }

  const handleFontChange = (event) => {
    const font = event.target.value

    const newStyles = {
      ...styleOptions,
      fontFamily: font,
    }

    handleStyleOptionsChange(
      {
        newStyles,
        setCurrentFocus,
        setStyleOptions,
        setTexts,
        styleOptions,
        textField,
        textId,
        texts,
      },
      providerPdfTemplateId
    )
  }

  const handleFontSizeChange = (event) => {
    const fontSize = event.target.value

    const newStyles = {
      ...styleOptions,
      fontSize,
    }

    handleStyleOptionsChange(
      {
        newStyles,
        setCurrentFocus,
        setStyleOptions,
        setTexts,
        styleOptions,
        textField,
        textId,
        texts,
      },
      providerPdfTemplateId
    )
  }

  const handleFontColorChange = (event) => {
    const color = event.target.value

    const newStyles = {
      ...styleOptions,
      color,
    }

    handleStyleOptionsChange(
      {
        newStyles,
        setCurrentFocus,
        setStyleOptions,
        setTexts,
        styleOptions,
        textField,
        textId,
        texts,
      },
      providerPdfTemplateId
    )
  }

  const handleTextAlignmentChange = (event, textAlign) => {
    const newStyles = {
      ...styleOptions,
      textAlign,
    }

    handleStyleOptionsChange(
      {
        newStyles,
        setCurrentFocus,
        setStyleOptions,
        setTexts,
        styleOptions,
        textField,
        textId,
        texts,
      },
      providerPdfTemplateId
    )
  }

  const handleChangeFontStyle = (event, style) => {
    let newStyle

    switch (style) {
      case 'fontStyle': {
        newStyle = variantStyles.fontStyle === 'italic' ? { [style]: 'unset' } : { [style]: 'italic' }
        break
      }
      case 'fontWeight': {
        newStyle = variantStyles.fontWeight === 'bold' ? { [style]: 'unset' } : { [style]: 'bold' }
        break
      }
      case 'textDecoration': {
        newStyle = variantStyles.textDecoration === 'underline' ? { [style]: 'none' } : { [style]: 'underline' }
        break
      }
    }

    const newStyles = {
      ...variantStyles,
      ...newStyle,
    }

    setVariantStyles(newStyles)

    handleStyleOptionsChange(
      {
        newStyles,
        setCurrentFocus,
        setStyleOptions,
        setTexts,
        styleOptions,
        textField,
        textId,
        texts,
      },
      providerPdfTemplateId
    )
  }

  return (
    <div>
      <FormControl classes={{ root: inputStyles.root }}>
        <InputLabel shrink id="text-field">
          Text Field
        </InputLabel>
        <Select
          labelId="text-field-label-label"
          id="text-field-label"
          value={textField}
          onChange={handleTextFieldChange}
          displayEmpty
        >
          <MenuItem value={'Choose a text field'}>
            <em>Choose a text field</em>
          </MenuItem>
          <MenuItem value={'Full Name'}>Full Name</MenuItem>
          <MenuItem value={'First Name'}>First Name</MenuItem>
          <MenuItem value={'Last Name'}>Last Name</MenuItem>
          <MenuItem value={'Email'}>Email</MenuItem>
          <MenuItem value={'Phone Number'}>Phone Number</MenuItem>
          <MenuItem value={'Custom Text'}>Custom Text</MenuItem>
        </Select>
      </FormControl>

      <FormControl classes={{ root: inputStyles.withTopMargin }}>
        <InputLabel shrink id="font-input">
          Font
        </InputLabel>
        <Select
          displayEmpty
          id="font-input-label"
          labelId="font-input-label-label"
          onChange={handleFontChange}
          style={{ fontFamily: styleOptions.fontFamily }}
          value={styleOptions.fontFamily}
        >
          {FONTS.map((fontItem) => {
            return (
              <MenuItem style={{ fontFamily: fontItem }} key={fontItem} value={fontItem}>
                {fontItem}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>

      <div className={styles.halfInputContainer}>
        <FormControl classes={{ root: inputStyles.halfInput }}>
          <InputLabel shrink id="font-size">
            Font Size
          </InputLabel>
          <Select
            labelId="font-size-label-label"
            id="font-size-label"
            value={styleOptions.fontSize}
            onChange={handleFontSizeChange}
          >
            {fontSizes.map((size) => {
              return (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <FormControl classes={{ root: inputStyles.halfInput }}>
          <InputLabel shrink id="font-color">
            Color
          </InputLabel>
          <div className={styles.color}>
            <input id="color" name="color" onChange={handleFontColorChange} type="color" value={styleOptions.color} />
          </div>
        </FormControl>
      </div>

      <div className={styles.halfInputContainer}>
        <FormControl classes={{ root: inputStyles.inputButtonsContainer }}>
          <InputLabel shrink id="alignment">
            Alignment
          </InputLabel>
          <div>
            <IconButton
              aria-label="left-alignment"
              classes={{ root: inputStyles.inputButtons }}
              onClick={(event) => handleTextAlignmentChange(event, 'left')}
              style={
                text.styleOptions.textAlign === 'left'
                  ? { backgroundColor: '#e0e0e0', border: '1px solid #1F8289' }
                  : {}
              }
            >
              <FormatAlignLeftIcon value={'left'} />
            </IconButton>
            <IconButton
              aria-label="center-alignment"
              classes={{ root: inputStyles.inputButtons }}
              onClick={(event) => handleTextAlignmentChange(event, 'center')}
              style={
                text.styleOptions.textAlign === 'center'
                  ? { backgroundColor: '#e0e0e0', border: '1px solid #1F8289' }
                  : {}
              }
            >
              <FormatAlignCenterIcon value={'center'} />
            </IconButton>
            <IconButton
              aria-label="right-alignment"
              classes={{ root: inputStyles.inputButtons }}
              onClick={(event) => handleTextAlignmentChange(event, 'right')}
              style={
                text.styleOptions.textAlign === 'right'
                  ? { backgroundColor: '#e0e0e0', border: '1px solid #1F8289' }
                  : {}
              }
            >
              <FormatAlignRightIcon value={'right'} />
            </IconButton>
          </div>
        </FormControl>

        <FormControl classes={{ root: inputStyles.inputButtonsContainer }}>
          <InputLabel shrink id="style">
            Style
          </InputLabel>
          <div>
            <IconButton
              aria-label="bold"
              classes={{ root: inputStyles.inputButtons }}
              onClick={(event) => handleChangeFontStyle(event, 'fontWeight')}
              style={
                text.styleOptions.fontWeight === 'bold'
                  ? { backgroundColor: '#e0e0e0', border: '1px solid #1F8289' }
                  : {}
              }
            >
              <FormatBoldIcon value={'bold'} />
            </IconButton>
            <IconButton
              aria-label="italic"
              classes={{ root: inputStyles.inputButtons }}
              onClick={(event) => handleChangeFontStyle(event, 'fontStyle')}
              style={
                text.styleOptions.fontStyle === 'italic'
                  ? { backgroundColor: '#e0e0e0', border: '1px solid #1F8289' }
                  : {}
              }
            >
              <FormatItalicIcon value={'italic'} />
            </IconButton>
            <IconButton
              aria-label="underline"
              classes={{ root: inputStyles.inputButtons }}
              onClick={(event) => handleChangeFontStyle(event, 'textDecoration')}
              style={
                text.styleOptions.textDecoration === 'underline'
                  ? { backgroundColor: '#e0e0e0', border: '1px solid #1F8289' }
                  : {}
              }
            >
              <FormatUnderlinedIcon value={'underline'} />
            </IconButton>
          </div>
        </FormControl>
      </div>

      <div className={styles.deleteCotainer}>
        <Button
          classes={{ root: inputStyles.deleteText }}
          color="default"
          endIcon={<DeleteOutlineIcon />}
          onClick={() => handleDeleteText(textId)}
          variant="text"
        >
          DELETE
        </Button>
      </div>
    </div>
  )
}

TextFieldForm.propTypes = {
  createImageHandler: PropTypes.func,
  createTextHandler: PropTypes.func,
  currentFocus: PropTypes.object,
  deleteImageHandler: PropTypes.func,
  handleDeleteText: PropTypes.func,
  images: PropTypes.array,
  providerPdfTemplateId: PropTypes.string,
  setCurrentFocus: PropTypes.func,
  setTexts: PropTypes.func,
  text: PropTypes.object,
  textId: PropTypes.string,
  texts: PropTypes.array,
}
