import { Link } from 'react-router-dom'
import { useState } from 'react'

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import './index.css'
import styles from './index.module.css'

function a11yProps(index) {
  return {
    'aria-controls': `nav-tabpanel-${index}`,
    id: `nav-tab-${index}`,
  }
}

function LinkTab(props) {
  return <Tab component={Link} {...props} />
}

export const HeaderTabs = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Tabs
        aria-label="Header Nav"
        className={styles.headerTabs}
        indicatorColor="primary"
        onChange={handleChange}
        value={value}
      >
        <LinkTab label="Documents" href="/documents" to="/promoter/documents" {...a11yProps(0)} />
        <LinkTab label="Account" href="/account" to="/promoter/account" {...a11yProps(1)} />
      </Tabs>
    </div>
  )
}
