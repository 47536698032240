import axios from 'axios'
import * as R from 'ramda'

export async function saveTemplate(template) {
  if (template.images) {
    template.images = R.map(R.pick(['id', 'label', 'location', 'type']), template.images)
  }

  if (template.texts) {
    template.texts = R.map(R.pick(['id', 'label', 'location', 'styleOptions', 'textField', 'type']), template.texts)
  }

  try {
    await axios.post('/api/provider/template/save', template)
  } catch (error) {
    console.log(error)
  }
}
