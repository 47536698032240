import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import axios from 'axios'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { useEffect, useState } from 'react'

import constants from 'src/constants'

import styles from './edit-promoter-groups-modal.module.css'

// const useStyles = makeStyles({
//   documentInput: materialStyles.documentUploadInput,
// })

export function EditPromoterGroupsModal(props) {
  const { promoters, selectedPromoterId, setShowEditPromoterGroupsModal, showEditPromoterGroupsModal } = props
  const [groupList, setGroupList] = useState([])

  useEffect(() => {
    async function getGroupsData() {
      try {
        const { data } = await axios.get(`/api/provider/promoters/${selectedPromoterId}/groups`)

        setGroupList(data)
      } catch (error) {
        console.log('Could not get promoters information!', error)
      }
    }

    if (selectedPromoterId) {
      getGroupsData()
    }
  }, [selectedPromoterId])

  const handleToggle = (event, group) => {
    const updateGroup = (group) => {
      return { ...group, promoterInGroup: !group.promoterInGroup }
    }

    const updatedGroups = R.adjust(R.findIndex(R.propEq('groupId', group.groupId), groupList), updateGroup, groupList)

    setGroupList(updatedGroups)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      await axios.post(`/api/provider/promoters/${selectedPromoterId}/groups`, groupList)
    } catch (error) {
      console.log('Problem updating promoters groups.', error)
    }

    setShowEditPromoterGroupsModal(false)
  }

  return showEditPromoterGroupsModal ? (
    <div className={styles.uploadPdfModalContainer}>
      <div className={styles.modalHeaderContainer}>
        <h1>Promoter Groups</h1>
        <IconButton
          onClick={() => {
            setShowEditPromoterGroupsModal(false)
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.inputsContainer}>
        <div className={styles.groupsHeader}>
          <h2>Groups</h2>
        </div>
        <form onSubmit={handleSubmit}>
          {groupList.map((group) => {
            return group.groupId === constants.groups.everyone.id ? (
              <div className={styles.inputContainer} key={group.groupId}>
                <input checked disabled type="checkbox" value={group.groupId} />
                <span className={classNames(styles.checkmark, styles.disabledCheckmark)}></span>
                <label>{group.displayName}</label>
              </div>
            ) : (
              <div className={styles.inputContainer} key={group.groupId}>
                <input
                  type="checkbox"
                  checked={group.promoterInGroup}
                  value={group.groupId}
                  onChange={(e) => e.preventDefault}
                />
                <span className={styles.checkmark} onClick={(e) => handleToggle(e, group)}></span>
                <label>{group.displayName}</label>
              </div>
            )
          })}
          <div className={styles.submitContainer}>
            <button className={styles.submitButton} type="submit">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : null
}

EditPromoterGroupsModal.propTypes = {
  setShowEditPromoterGroupsModal: PropTypes.func,
  showEditPromoterGroupsModal: PropTypes.bool,
}
