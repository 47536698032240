export const header = {
  cells: {
    color: 'white',
    width: '500px',
  },
  root: {
    backgroundColor: '#333333',
    width: '500px',
  },
}
