import axios from 'axios'

export const getS3SignedUrl = async ({ file, type, name }) => {
  const signedUrlResponse = await axios.post('/api/promoter/image/upload', {
    file,
    fileType: file.type || type,
    filename: file.name || name,
  })

  const signedUrl = signedUrlResponse.data.signedUrl
  const s3Location = signedUrlResponse.data.s3Url

  return {
    s3Location,
    signedUrl,
  }
}

export const uploadImageToS3 = async ({ signedUrl, file }) => {
  let result

  const axiosInstanceForS3Upload = axios.create()

  try {
    result = await axiosInstanceForS3Upload.put(signedUrl, file)
  } catch (error) {
    console.log('ERROR for promoter S3 upload 😞', error.response)
  }
  return result
}

export const updatePromoterPdfTemplate = async ({ promoterPdfTemplateId, promoterPdfTemplateUpdates }) => {
  try {
    await axios.post(`/api/promoter/promoter-pdf-template/${promoterPdfTemplateId}`, { promoterPdfTemplateUpdates })
  } catch (error) {
    console.error('Could not save promoter pdf template', error)
  }
}
