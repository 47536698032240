import axios from 'axios'
import { ReactComponent as VisibilityEnabledIcon } from 'src/images/visibility-enabled-icon.svg'
import { ReactComponent as VisibilityDisabledIcon } from 'src/images/visibility-disabled-icon.svg'

import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useState } from 'react'

import constants from 'src/constants'
import styles from './index.module.css'

export function ChangePassword() {
  const [visibilityStates, setVisibilityStates] = useState({
    confirmPassword: false,
    currentPassword: false,
    newPassword: false,
  })

  const { formState, register, handleSubmit, reset } = useForm({
    defaultValues: {
      confirmNewPassword: '',
      currentPassword: '',
      newPassword: '',
    },
  })

  const onSubmit = async (data) => {
    const { currentPassword, newPassword, confirmNewPassword } = data

    if (newPassword !== confirmNewPassword) {
      return alert('Your new passwords do not match')
    }

    if (newPassword.length < constants.minimumPasswordLength) {
      return alert('Your new password is too short. Minimum of 12 characters required.')
    }

    await axios.post(constants.routes.changePassword, { currentPassword, newPassword })

    reset()
  }

  const toggleVisibility = (key) => {
    setVisibilityStates({ ...visibilityStates, [key]: !visibilityStates[key] })
  }

  return (
    <div className={styles.changePasswordContainer}>
      <h1 className={styles.changePasswordTitle}>Change Password</h1>
      <div className={styles.underline} />
      <form>
        <div className={styles.passwordContainer}>
          <div className={styles.textBox}>
            <div className={styles.leftContainer}>
              <label>Current Password</label>
              <input
                type={visibilityStates['currentPassword'] ? 'text' : 'password'}
                name="currentPassword"
                ref={register()}
                className={styles.input}
              />
            </div>
            {visibilityStates['currentPassword'] ? (
              <button
                className={styles.removeButtonStyles}
                type="button"
                onClick={() => toggleVisibility('currentPassword')}
              >
                <VisibilityDisabledIcon className={styles.visibilityEnabledIcon} />
              </button>
            ) : (
              <button
                className={styles.removeButtonStyles}
                type="button"
                onClick={() => toggleVisibility('currentPassword')}
              >
                <VisibilityEnabledIcon className={styles.visibilityEnabledIcon} />
              </button>
            )}
          </div>
        </div>
        <div className={styles.passwordContainer}>
          <div className={styles.textBox}>
            <div className={styles.leftContainer}>
              <label>New Password</label>
              <input
                type={visibilityStates['newPassword'] ? 'text' : 'password'}
                name="newPassword"
                ref={register()}
                className={styles.input}
              />
            </div>
            {visibilityStates['newPassword'] ? (
              <button
                className={styles.removeButtonStyles}
                type="button"
                onClick={() => toggleVisibility('newPassword')}
              >
                <VisibilityDisabledIcon className={styles.visibilityEnabledIcon} />
              </button>
            ) : (
              <button
                className={styles.removeButtonStyles}
                type="button"
                onClick={() => toggleVisibility('newPassword')}
              >
                <VisibilityEnabledIcon className={styles.visibilityEnabledIcon} />
              </button>
            )}
          </div>
        </div>
        <div className={styles.passwordContainer}>
          <div className={styles.textBox}>
            <div className={styles.leftContainer}>
              <label>Confirm New Password</label>
              <input
                type={visibilityStates['confirmNewPassword'] ? 'text' : 'password'}
                name="confirmNewPassword"
                ref={register()}
                className={styles.input}
              />
            </div>
            {visibilityStates['confirmNewPassword'] ? (
              <button
                className={styles.removeButtonStyles}
                type="button"
                onClick={() => toggleVisibility('confirmNewPassword')}
              >
                <VisibilityDisabledIcon className={styles.visibilityEnabledIcon} />
              </button>
            ) : (
              <button
                className={styles.removeButtonStyles}
                type="button"
                onClick={() => toggleVisibility('confirmNewPassword')}
              >
                <VisibilityEnabledIcon className={styles.visibilityEnabledIcon} />
              </button>
            )}
          </div>
        </div>
        <button
          disabled={!formState.isDirty}
          className={classNames({
            [styles.button]: true,
            [styles.buttonDisabled]: !formState.isDirty,
          })}
          onClick={handleSubmit(onSubmit)}
          type="submit"
        >
          Save
        </button>
      </form>
    </div>
  )
}
