import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import PropTypes from 'prop-types'

import styles from './index.module.css'

export const ImageNeeded = (props) => {
  const { image, imageIndex, imageEditorDrawerOpen, setImageEditorDrawerOpen, setImageToEdit } = props

  const addImageButton = () => {
    setImageToEdit(image)
    setImageEditorDrawerOpen(true)
  }

  const imageBoxStyles = imageEditorDrawerOpen
    ? `${styles.uploadImage} ${styles.uploadingImage}`
    : `${styles.uploadImage}`

  return (
    <div className={styles.imageContainer}>
      <div className={styles.imageLabelContainer}>
        <div className={styles.number}>{imageIndex}</div>
        <p className={styles.label}>{image.label}</p>
      </div>
      <div className={imageBoxStyles}>
        <CloudUploadOutlinedIcon fontSize="large" />
        <p>Image is missing</p>
        <button className={styles.addImageButton} onClick={addImageButton}>
          Add Image
        </button>
      </div>
    </div>
  )
}

ImageNeeded.propTypes = {
  image: PropTypes.object,
  imageEditorDrawerOpen: PropTypes.bool,
  imageIndex: PropTypes.number,
  setImageEditorDrawerOpen: PropTypes.func,
  setImageToEdit: PropTypes.func,
}
