import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import styles from './image-editor-drawer.module.css'

export const ImageEditorDrawer = (props) => {
  const { imageEditorDrawerOpen, setImageEditorDrawerOpen, setUploadImageModalOpen } = props

  const drawer = imageEditorDrawerOpen ? (
    <div className={styles.drawer}>
      <div className={styles.drawerContentsContainer}>
        <div className={styles.drawerHeader}>
          <button onClick={() => setImageEditorDrawerOpen(false)}>X</button>
          <p>Choose a File</p>
        </div>
        <div className={styles.drawerContents}>
          <button className={styles.uploadButton} onClick={() => setUploadImageModalOpen(true)}>
            Upload
            <CloudUploadIcon />
          </button>
        </div>
      </div>
    </div>
  ) : null

  return drawer
}
