import axios from 'axios'
import * as R from 'ramda'
import { Document, Page, pdfjs } from 'react-pdf'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import ToolTip from 'react-tooltip'
import { useHistory } from 'react-router-dom'

import constants from 'src/constants'

import styles from './index.module.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export function DocumentsDisplay(props) {
  const { renameDocumentModal, setRenameDocumentModal, setUploadPdfModal } = props
  const [providerPdfTemplates, setProviderPdfTemplates] = useState([])

  useEffect(() => {
    async function getProviderPdfTemplates() {
      const endpoint = constants.routes.provider.getProviderPdfTemplates

      const { data: providerPdfTemplates } = await axios.get(endpoint)

      setProviderPdfTemplates(providerPdfTemplates)
    }

    getProviderPdfTemplates()
  }, [])

  return (
    <div className={styles.documentsDisplayContainer}>
      {providerPdfTemplates.map((providerPdfTemplate) => {
        return (
          <PdfTile
            providerPdfTemplates={providerPdfTemplates}
            setProviderPdfTemplates={setProviderPdfTemplates}
            providerPdfTemplate={providerPdfTemplate}
            key={providerPdfTemplate.id}
            renameDocumentModal={renameDocumentModal}
            setRenameDocumentModal={setRenameDocumentModal}
            setUploadPdfModal={setUploadPdfModal}
          />
        )
      })}
    </div>
  )
}

DocumentsDisplay.propTypes = {
  renameDocumentModal: PropTypes.object,
  setRenameDocumentModal: PropTypes.func,
  setUploadPdfModal: PropTypes.func,
}

const PdfTile = (props) => {
  const { providerPdfTemplate, renameDocumentModal, setRenameDocumentModal } = props
  const history = useHistory()

  const handlePdfTileClick = () => {
    history.push(`/provider/documents/${props.providerPdfTemplate.id}`)
  }

  const { documentName, s3Location } = props.providerPdfTemplate

  return (
    <div className={styles.pdfTile} onClick={handlePdfTileClick}>
      <Document className={styles.document} file={s3Location} onLoadError={console.error}>
        <div className={styles.pdfPage}>
          <Page width={250} pageNumber={1} />
        </div>
      </Document>
      <DocumentFooter
        documentName={documentName}
        providerPdfTemplate={providerPdfTemplate}
        providerPdfTemplateId={props.providerPdfTemplate.id}
        providerPdfTemplates={props.providerPdfTemplates}
        setProviderPdfTemplates={props.setProviderPdfTemplates}
        renameDocumentModal={renameDocumentModal}
        setRenameDocumentModal={setRenameDocumentModal}
        setUploadPdfModal={props.setUploadPdfModal}
      />
    </div>
  )
}

PdfTile.propTypes = {
  providerPdfTemplate: PropTypes.shape({
    documentName: PropTypes.string,
    id: PropTypes.string,
    s3Location: PropTypes.string,
  }),
  providerPdfTemplates: PropTypes.array,
  renameDocumentModal: PropTypes.object,
  setProviderPdfTemplates: PropTypes.func,
  setRenameDocumentModal: PropTypes.func,
  setUploadPdfModal: PropTypes.func,
}

const DocumentFooter = (props) => {
  const { renameDocumentModal, setRenameDocumentModal } = props

  const [toolTipDisabled, setToolTipDisabled] = useState(true)

  const handleHover = (event) => {
    const hasEllipsis = event.target.offsetWidth < event.target.scrollWidth

    setToolTipDisabled(!hasEllipsis)
  }

  return (
    <div className={styles.documentOptionsContainer}>
      <div
        data-delay-hide="500"
        data-tip
        data-for={props.documentName}
        className={styles.documentName}
        onMouseEnter={handleHover}
      >
        {props.documentName}
      </div>
      <ToolTip disable={toolTipDisabled} id={props.documentName} className={styles.documentNameToolTip} effect="solid">
        <span>{props.documentName}</span>
      </ToolTip>
      <DocumentOptions
        className={styles.documentOptions}
        providerPdfTemplateId={props.providerPdfTemplateId}
        setProviderPdfTemplates={props.setProviderPdfTemplates}
        providerPdfTemplates={props.providerPdfTemplates}
        providerPdfTemplate={props.providerPdfTemplate}
        renameDocumentModal={renameDocumentModal}
        setRenameDocumentModal={setRenameDocumentModal}
        setUploadPdfModal={props.setUploadPdfModal}
      />
    </div>
  )
}

DocumentFooter.propTypes = {
  documentName: PropTypes.string,
  providerPdfTemplate: PropTypes.object,
  providerPdfTemplateId: PropTypes.string,
  providerPdfTemplates: PropTypes.array,
  renameDocumentModal: PropTypes.object,
  setProviderPdfTemplates: PropTypes.func,
  setRenameDocumentModal: PropTypes.func,
  setUploadPdfModal: PropTypes.func,
}

const options = ['Rename', 'Replace', 'Delete']

const ITEM_HEIGHT = 48

function DocumentOptions(props) {
  const { providerPdfTemplate, setRenameDocumentModal } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)

    event.stopPropagation()
  }

  const handleClose = async (event, option = '') => {
    setAnchorEl(null)
    event.stopPropagation()

    if (option.toLowerCase() === 'delete') {
      const endpoint = constants.routes.provider.deleteProviderPdfTemplate

      await axios.delete(`${endpoint}/${props.providerPdfTemplateId}`)

      const providerPdfTemplates = R.reject(R.propEq('id', props.providerPdfTemplateId), props.providerPdfTemplates)

      props.setProviderPdfTemplates(providerPdfTemplates)
    }

    if (option.toLowerCase() === 'replace') {
      props.setUploadPdfModal({ isOpen: true, providerPdfTemplateId: props.providerPdfTemplateId })
    }

    if (option.toLocaleLowerCase() === 'rename') {
      setRenameDocumentModal({ isOpen: true, pdfId: providerPdfTemplate.providerPdfId })
    }
  }

  return (
    <div>
      <IconButton aria-label="more" aria-controls="menu" aria-haspopup="true" onClick={handleClick}>
        <MoreHorizIcon style={{ fill: 'black' }} />
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={(evt) => handleClose(evt, option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

DocumentOptions.propTypes = {
  providerPdfTemplate: PropTypes.object,
  providerPdfTemplateId: PropTypes.string,
  providerPdfTemplates: PropTypes.array,
  renameDocumentModal: PropTypes.object,
  setProviderPdfTemplates: PropTypes.func,
  setRenameDocumentModal: PropTypes.func,
  setUploadPdfModal: PropTypes.func,
}
