import PropTypes from 'prop-types'
import * as R from 'ramda'

import { ReactComponent as EditPencilIcon } from 'src/images/edit-pencil-icon.svg'
import { returnFileSize } from 'src/utils/uploads'
import { ImageNeeded } from '../image-needed'
import constants from 'src/constants'

import styles from './index.module.css'

export function DefaultPhotos(props) {
  const {
    defaultImageInputRef,
    defaultUserImages,
    setSelectedFile,
    setUploadDefaultImageModalIsOpen,
    setUserSelectedDefaultPhoto,
  } = props

  const handleEditImage = (event, userDefaultImage, defaultImageCategory) => {
    event.preventDefault()
    setUserSelectedDefaultPhoto(userDefaultImage)
    setUploadDefaultImageModalIsOpen(true)

    const file = event.target.files[0]
    const fileSize = returnFileSize(file.size)
    const fileSizeLimit = returnFileSize(constants.fileSizeLimit)
    file.fileSize = fileSize
    file.documentType = defaultImageCategory

    if (file.size > constants.fileSizeLimit) {
      window.alert(`Your file is ${fileSize}. Please select an image that is less than ${fileSizeLimit}`)
      return
    }

    setSelectedFile({ details: event.target.files[0], file: URL.createObjectURL(event.target.files[0]) })
    defaultImageInputRef.current = {
      details: event.target.files[0],
      documentType: defaultImageCategory,
      file: event.target.files[0],
    }
  }

  const defaultImages = constants.defaultImageCategories.map((defaultImageCategory) => {
    const userDefaultImage = R.find(R.propEq('type', defaultImageCategory), defaultUserImages)

    return (
      <div className={styles.defaultImageCategoryContainer} key={defaultImageCategory}>
        <h3 className={styles.imageType}>{defaultImageCategory}</h3>
        <div className={styles.imageContainer}>
          {userDefaultImage ? (
            <>
              <img src={userDefaultImage.s3Location} />
              <div className={styles.labelHackContainer}>
                <label className={styles.fileUploadHackLabel}>
                  <div className={styles.editImageContainer}>
                    <p>Change Image</p>
                    <EditPencilIcon />
                    <input
                      className={styles.customFileInput}
                      onChange={(event) => handleEditImage(event, userDefaultImage, defaultImageCategory)}
                      ref={defaultImageInputRef}
                      type="file"
                    />
                  </div>
                </label>
              </div>
            </>
          ) : (
            <ImageNeeded
              defaultImageInputRef={defaultImageInputRef}
              defaultImageCategory={defaultImageCategory}
              setUploadDefaultImageModalIsOpen={setUploadDefaultImageModalIsOpen}
              setSelectedFile={setSelectedFile}
            />
          )}
        </div>
      </div>
    )
  })

  return (
    <div className={styles.defaultPhotosContainer}>
      <h2 className={styles.defaultPhotosTitle}>Default Photos</h2>
      <hr />
      <div className={styles.imagesContainers}>{defaultImages}</div>
      <div className={styles.underline} />
    </div>
  )
}

DefaultPhotos.propTypes = {
  defaultImageInputRef: PropTypes.object,
  defaultUserImages: PropTypes.array,
  selectedFile: PropTypes.object,
  setSelectedFile: PropTypes.func,
  setUploadDefaultImageModalIsOpen: PropTypes.func,
  setUserSelectedDefaultPhoto: PropTypes.func,
}
