import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'

import { Admin } from './pages/admin'

import { ProviderPages } from './pages/provider'
import { PromoterPages } from './pages/promoter'

import { SignIn } from './pages/sign-in'
import { SignUp } from './pages/sign-up/promoter'

import { InitializePendo } from './initialize-pendo'

// import { RedirectIfNecessary } from './utils/redirect-if-necessary'

function Routes() {
  return (
    <Router>
      <InitializePendo />
      {/* <RedirectIfNecessary /> */}
      <Switch>
        <Route path="/provider">
          <ProviderPages />
        </Route>
        <Route path="/promoter">
          <PromoterPages />
        </Route>
        <Route path="/sign-in">
          <SignIn />
        </Route>
        <Route path="/sign-up/promoter/:pendingUserId">
          <SignUp />
        </Route>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route render={() => <Redirect to="/sign-in" />} />
      </Switch>
    </Router>
  )
}

export { Routes }
