import Box from '@material-ui/core/Box'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { Images } from './images'
import { Share } from './share'
import { Text } from './text'

import styles from './index.module.css'
import * as materialStyles from './styles'

function a11yProps(index) {
  return {
    'aria-controls': `nav-tabpanel-${index}`,
    id: `nav-tab-${index}`,
  }
}

const useTabNavigationStyles = makeStyles({
  flexContainer: materialStyles.tabNavigation.flexContainer,
  root: materialStyles.tabNavigation.root,
})

const useTabStyles = makeStyles({
  root: materialStyles.tab.root,
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
}

export const RightSidebar = (props) => {
  const [value, setValue] = useState(0)

  const tabStyles = useTabStyles()
  const tabNavigationStyles = useTabNavigationStyles()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={styles.rightSidebar}>
      <nav className={styles.rightNavigation}>
        <Tabs
          aria-label="Header Nav"
          classes={{ flexContainer: tabNavigationStyles.flexContainer, root: tabNavigationStyles.root }}
          indicatorColor="primary"
          onChange={handleChange}
          value={value}
        >
          <Tab classes={{ root: tabStyles.root }} label="Images" {...a11yProps(0)} />
          <Tab classes={{ root: tabStyles.root }} label="Text" {...a11yProps(1)} />
          <Tab classes={{ root: tabStyles.root }} label="Share" {...a11yProps(2)} />
        </Tabs>
      </nav>
      <TabPanel value={value} index={0}>
        <Images
          createImageHandler={props.createImageHandler}
          deleteImageHandler={props.deleteImageHandler}
          images={props.images}
          setImages={props.setImages}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Text
          createTextHandler={props.createTextHandler}
          currentFocus={props.currentFocus}
          handleDeleteText={props.handleDeleteText}
          setCurrentFocus={props.setCurrentFocus}
          setTexts={props.setTexts}
          providerPdfTemplateId={props.providerPdfTemplateId}
          texts={props.texts}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Share providerPdfTemplateId={props.providerPdfTemplateId} />
      </TabPanel>
    </div>
  )
}

RightSidebar.propTypes = {
  createImageHandler: PropTypes.func,
  createTextHandler: PropTypes.func,
  currentFocus: PropTypes.object,
  deleteImageHandler: PropTypes.func,
  handleDeleteText: PropTypes.func,
  images: PropTypes.array,
  providerPdfTemplateId: PropTypes.string,
  setCurrentFocus: PropTypes.func,
  setImages: PropTypes.func,
  setTexts: PropTypes.func,
  texts: PropTypes.array,
}
