/* eslint-disable */

export const PieceKeeperLogoSvg = () => {
  return (
    <svg width="370px" height="53px" viewBox="0 0 349 53" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>98C5EB90-5196-4B9C-92A4-6520EFB6EDEC</title>
      <g id="Provider-Portal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Login" transform="translate(-566.000000, -130.000000)" fill="#31383E">
          <g id="Logo-/-PK-/-NEW-/-1" transform="translate(556.000000, 130.000000)">
            <g id="Logo-/-PK-/-Icon" transform="translate(0.000000, 5.000000)" fillRule="nonzero">
              <g id="Logo-/-Icon">
                <ellipse id="Oval" cx="27.4856936" cy="27.4472727" rx="8.08402753" ry="8.07272727"></ellipse>
                <path
                  d="M8.08402753,19.3745455 C12.5487126,19.3745455 16.1680551,22.9888286 16.1680551,27.4472727 C16.1680551,31.9057169 12.5487126,35.52 8.08402753,35.52 C3.61934241,35.52 0,31.9057169 0,27.4472727 C0,22.9888286 3.61934241,19.3745455 8.08402753,19.3745455 Z M8.08402753,22.6036364 C5.40521645,22.6036364 3.23361101,24.7722062 3.23361101,27.4472727 C3.23361101,30.1223392 5.40521645,32.2909091 8.08402753,32.2909091 C10.7628386,32.2909091 12.934444,30.1223392 12.934444,27.4472727 C12.934444,24.7722062 10.7628386,22.6036364 8.08402753,22.6036364 Z"
                  id="Ring"
                ></path>
                <ellipse id="Oval" cx="27.4856936" cy="8.07272727" rx="8.08402753" ry="8.07272727"></ellipse>
                <path
                  d="M8.08402753,0 C12.5487126,0 16.1680551,3.61428311 16.1680551,8.07272727 C16.1680551,12.5311714 12.5487126,16.1454545 8.08402753,16.1454545 C3.61934241,16.1454545 0,12.5311714 0,8.07272727 C0,3.61428311 3.61934241,0 8.08402753,0 Z M8.08402753,3.22909091 C5.40521645,3.22909091 3.23361101,5.39766078 3.23361101,8.07272727 C3.23361101,10.7477938 5.40521645,12.9163636 8.08402753,12.9163636 C10.7628386,12.9163636 12.934444,10.7477938 12.934444,8.07272727 C12.934444,5.39766078 10.7628386,3.22909091 8.08402753,3.22909091 Z"
                  id="Ring"
                ></path>
              </g>
            </g>
            <text id="PieceKeeper" fontFamily="Futura-Bold, Futura" fontSize="47.488002" fontWeight="bold">
              <tspan x="44.4621514" y="39.4899181">
                PieceKeeper
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  )
}
