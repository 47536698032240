import Box from '@material-ui/core/Box'
import { useState } from 'react'
import PropTypes from 'prop-types'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { makeStyles } from '@material-ui/core/styles'

import { Images } from './images.js'
import { Texts } from './texts.js'

import * as materialStyles from './styles'
import styles from './index.module.css'

function a11yProps(index) {
  return {
    'aria-controls': `nav-tabpanel-${index}`,
    id: `nav-tab-${index}`,
  }
}

const useTabStyles = makeStyles({
  flexTabsNavigation: materialStyles.tabNavigation.flexContainer,
  rootTabsNavigation: materialStyles.tabNavigation.root,
  tab: materialStyles.tab.root,
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
}

export const RightSidebar = (props) => {
  const [value, setValue] = useState(0)

  const tabStyles = useTabStyles()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={styles.rightSidebar}>
      <nav className={styles.rightNavigation}>
        <Tabs
          aria-label="Header Nav"
          classes={{ flexContainer: tabStyles.flexTabsNavigation, root: tabStyles.rootTabsNavigation }}
          indicatorColor="primary"
          onChange={handleChange}
          value={value}
        >
          <Tab classes={{ root: tabStyles.tab }} label="Images" {...a11yProps(0)} />
          <Tab classes={{ root: tabStyles.tab }} label="Text" {...a11yProps(1)} />
        </Tabs>
      </nav>
      <TabPanel value={value} index={0}>
        <Images
          images={props.images}
          imageEditorDrawerOpen={props.imageEditorDrawerOpen}
          setImageEditorDrawerOpen={props.setImageEditorDrawerOpen}
          setEditImageModalOpen={props.setEditImageModalOpen}
          imageToEdit={props.imageToEdit}
          setImageToEdit={props.setImageToEdit}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Texts
          texts={props.texts}
          saveTextModalOpen={props.saveTextModalOpen}
          setTextFieldToUpdate={props.setTextFieldToUpdate}
          setUpdateTextModalOpen={props.setUpdateTextModalOpen}
        />
      </TabPanel>
    </div>
  )
}

RightSidebar.propTypes = {
  createImageHandler: PropTypes.func,
  createTextHandler: PropTypes.func,
  deleteImageHandler: PropTypes.func,
  imageEditorDrawerOpen: PropTypes.bool,
  imageToEdit: PropTypes.object,
  images: PropTypes.array,
  saveTextModalOpen: PropTypes.bool,
  setEditImageModalOpen: PropTypes.func,
  setImageEditorDrawerOpen: PropTypes.func,
  setImageToEdit: PropTypes.func,
  setTextFieldToUpdate: PropTypes.func,
  setUpdateTextModalOpen: PropTypes.func,
  texts: PropTypes.array,
}
