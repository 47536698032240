import axios from 'axios'
import Cookies from 'js-cookie'
import IconButton from '@material-ui/core/IconButton'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PropTypes from 'prop-types'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'

import constants from 'src/constants'
import { ReactComponent as PieceKeeperLogoSvg } from 'src/images/piece-keeper-logo.svg'
import { ReactComponent as EditPencilIcon } from 'src/images/error.svg'

import * as materialStyles from './styles'
import styles from './index.module.css'

const useStyles = makeStyles({
  iconButton: materialStyles.iconButton.root,
  leftIcons: materialStyles.icons.left,
  rightIcons: materialStyles.icons.right,
})

const LoginFeedback = ({ failedLogin }) => {
  return (
    <div
      className={
        failedLogin
          ? `${styles.loginFeedbackContainer} ${styles.loginFailedFeedbackContainer}`
          : styles.loginFeedbackContainer
      }
    >
      {failedLogin ? (
        <>
          <EditPencilIcon className={styles.errorIcon} />
          <p className={styles.loginFeedback}>The email and password you entered did not match our records.</p>
        </>
      ) : null}
    </div>
  )
}

LoginFeedback.propTypes = {
  failedLogin: PropTypes.bool,
}

export function SignIn() {
  const [focus, setFocus] = useState({ email: false, password: false })
  const [seePassword, setSeePassword] = useState(false)
  const [failedLogin, setFailedLogin] = useState(false)

  const location = useLocation()
  const history = useHistory()

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const iconStyles = useStyles()

  const focusStyle = {
    container: {
      borderBottom: '2px solid #1f8289',
      color: '#1f8289',
    },
    label: {
      color: '#1f8289',
    },
  }

  const onSubmit = async (data) => {
    const { redirectUrl } = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}

    try {
      await axios.post(constants.routes.signIn, {
        email: data.email,
        password: data.password,
      })
    } catch (error) {
      setFailedLogin(true)
    }

    if (redirectUrl) return history.push(redirectUrl)

    const loggedIn = Cookies.get('piecekeeper-logged-in')

    if (loggedIn === 'promoter') {
      history.push('/promoter/documents')
    }

    if (loggedIn === 'provider') {
      history.push('/provider/documents')
    }

    if (loggedIn === 'admin') {
      history.push('/admin')
    }
  }

  const handleShowPassword = () => {
    setSeePassword(!seePassword)
  }

  const handleFocus = (input) => {
    setFocus({ ...focus, [input]: true })
  }

  const handleBlur = (input) => {
    setFocus({ ...focus, [input]: false })
  }

  return (
    <div className={styles.signInPage}>
      <div className={styles.signInBox}>
        <div className={styles.signInContainer}>
          <PieceKeeperLogoSvg className={styles.pieceKeeperLogo} />
          <LoginFeedback failedLogin={failedLogin} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.userInputsContainer}>
              <div className={styles.userInput} style={focus.email ? focusStyle.container : null}>
                <MailOutlineIcon classes={{ root: iconStyles.leftIcons }} />
                <div className={styles.labelAndInput}>
                  <label style={focus.email ? focusStyle.label : null}>Email Address</label>
                  <input
                    name="email"
                    onBlur={() => handleBlur('email')}
                    onFocus={() => handleFocus('email')}
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              <div className={styles.userInput} style={focus.password ? focusStyle.container : null}>
                <LockOutlinedIcon classes={{ root: iconStyles.leftIcons }} />
                <div className={styles.labelAndInput}>
                  <label style={focus.password ? focusStyle.label : null}>Password</label>
                  <input
                    name="password"
                    onBlur={() => handleBlur('password')}
                    onFocus={() => handleFocus('password')}
                    ref={register({ required: true })}
                    type={seePassword ? 'text' : 'password'}
                  />
                </div>
                <IconButton
                  classes={{ root: iconStyles.iconButton }}
                  aria-label="toggle password visibility"
                  onClick={handleShowPassword}
                >
                  {seePassword ? (
                    <VisibilityOff classes={{ focused: iconStyles.testIcons, root: iconStyles.rightIcons }} />
                  ) : (
                    <Visibility classes={{ focused: iconStyles.testIcons, root: iconStyles.rightIcons }} />
                  )}
                </IconButton>
              </div>
            </div>
            <button className={styles.button} type="submit" variant="contained">
              SIGN IN
            </button>
          </form>
          <div className={styles.signInPrompts}>
            <a href="">Create An Account</a>
            <a href="">Forgot Your Password?</a>
          </div>
        </div>
      </div>
    </div>
  )
}
