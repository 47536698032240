import axios from 'axios'
import PropTypes from 'prop-types'
import ToolTip from 'react-tooltip'
import { Document, Page, pdfjs } from 'react-pdf'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import constants from 'src/constants'

import styles from './index.module.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export function DocumentsDisplay() {
  const [promoterDocuments, setPromoterDocuments] = useState([])

  useEffect(() => {
    const getPromoterPdfTemplates = async () => {
      const { data } = await axios.get(constants.routes.promoter.getPdfTemplates)

      setPromoterDocuments(data)
    }

    getPromoterPdfTemplates()
  }, [])

  return (
    <div className={styles.documentsDisplayContainer}>
      {promoterDocuments.map((providerDocument) => {
        return <PdfTile providerDocument={providerDocument} key={providerDocument.promoterPdfTemplateId} />
      })}
    </div>
  )
}

const PdfTile = (props) => {
  const history = useHistory()

  const handlePdfTileClick = () => {
    history.push(`/promoter/documents/${props.providerDocument.promoterPdfTemplateId}`)
  }

  const { providerPdfDocumentName: documentTitle, providerPdfS3Location: documentUrl } = props.providerDocument

  return (
    <div className={styles.pdfTile} onClick={handlePdfTileClick}>
      <Document className={styles.document} file={documentUrl} onLoadError={console.error}>
        <div className={styles.pdfPage}>
          <Page width={250} pageNumber={1} />
        </div>
      </Document>
      <DocumentFooter documentTitle={documentTitle} />
    </div>
  )
}

PdfTile.propTypes = {
  providerDocument: PropTypes.shape({
    promoterPdfTemplateId: PropTypes.string,
    providerPdfDocumentName: PropTypes.string,
    providerPdfS3Location: PropTypes.string,
  }),
}

const DocumentFooter = (props) => {
  const [toolTipDisabled, setToolTipDisabled] = useState(true)

  const handleHover = (event) => {
    const hasEllipsis = event.target.offsetWidth < event.target.scrollWidth

    setToolTipDisabled(!hasEllipsis)
  }

  return (
    <div className={styles.documentOptionsContainer}>
      <div
        data-delay-hide="500"
        data-tip
        data-for={props.documentTitle}
        className={styles.documentTitle}
        onMouseEnter={handleHover}
      >
        {props.documentTitle}
      </div>
      <ToolTip
        disable={toolTipDisabled}
        id={props.documentTitle}
        className={styles.documentTitleToolTip}
        effect="solid"
      >
        <span>{props.documentTitle}</span>
      </ToolTip>
    </div>
  )
}

DocumentFooter.propTypes = {
  documentTitle: PropTypes.string,
}
