import axios from 'axios'
import Cookies from 'js-cookie'
import * as R from 'ramda'
import { useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { PdfEditor } from '../pdf-editor'
import { UploadPdfModal } from '../modals/upload-pdf-modal'

export function ProviderDocumentDetails() {
  const [fontList, setFontList] = useState([])
  const [providerPdf, setProviderPdf] = useState(null)
  const [showUploadPdfModal, setShowUploadPdfModal] = useState({ isOpen: false, providerPdfTemplateId: null })

  useEffect(() => {
    async function fetchGoogleFonts() {
      try {
        const response = await axios.get(
          'https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyCcj3Xllmi1y-HEqg62i3-a5RqFgIHRofI'
        )

        const fontFamilies = R.compose(R.sort(R.comparator(R.lt)), R.take(25), R.pluck('family'))(response.data.items)

        setFontList(fontFamilies)
      } catch (error) {
        console.log(error)
      }
    }

    if (R.empty(fontList)) {
      fetchGoogleFonts()
    }
  }, [])

  const loggedIn = Cookies.get('piecekeeper-logged-in')

  const location = useLocation()

  if (!loggedIn) return <Redirect to={`/sign-in?redirectUrl=${location.pathname}`} />

  return (
    <div>
      <PdfEditor
        fontList={fontList}
        setShowUploadPdfModal={setShowUploadPdfModal}
        providerPdf={providerPdf}
        setProviderPdf={setProviderPdf}
      />
      <UploadPdfModal
        providerPdf={providerPdf}
        setProviderPdf={setProviderPdf}
        setShowUploadPdfModal={setShowUploadPdfModal}
        showUploadPdfModal={showUploadPdfModal}
      />
    </div>
  )
}
