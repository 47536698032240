import axios from 'axios'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import { useState } from 'react'
import TextField from '@material-ui/core/TextField'

import constants from 'src/constants'

import styles from './index.module.css'

const handleCreatePromoter = async (promoter, setCreateNewPromoterModal, refreshPromoters) => {
  // TODO: We should do form validation and errors in the form instead of window alerts
  if (!promoter) {
    window.alert('Please enter promoter information')
    return
  }

  if (!promoter.firstName) {
    window.alert('Please add a first name')
    return
  }

  if (!promoter.lastName) {
    window.alert('Please add a last name')
    return
  }

  if (!promoter.email) {
    window.alert('Please add an email')
    return
  }

  if (!promoter.password) {
    window.alert('Please add an initial password for the user')
    return
  }

  await axios.post(constants.routes.provider.createPromoterUser, promoter)

  setCreateNewPromoterModal(false)
  refreshPromoters.setToggle(!refreshPromoters.toggle)
}

export const CreateNewPromoterModal = React.forwardRef((props, ref) => {
  const { setCreateNewPromoterModal } = props

  const [newPromoter, setNewPromoter] = useState(null)

  return (
    <div className={styles.uploadPdfModalContainer} ref={ref}>
      <div className={styles.modalHeaderContainer}>
        <p>New Promoter</p>
        <IconButton
          onClick={() => {
            setCreateNewPromoterModal(false)
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.inputsContainer}>
        <TextField
          className={styles.documentInput}
          label="Promoter First Name"
          onChange={(evt) =>
            setNewPromoter({
              ...newPromoter,
              firstName: evt.target.value,
            })
          }
        />
        <TextField
          className={styles.documentInput}
          label="Promoter Last Name"
          onChange={(evt) =>
            setNewPromoter({
              ...newPromoter,
              lastName: evt.target.value,
            })
          }
        />
        <TextField
          className={styles.documentInput}
          label="Promoter Email"
          onChange={(evt) =>
            setNewPromoter({
              ...newPromoter,
              email: evt.target.value,
            })
          }
        />
        <TextField
          className={styles.documentInput}
          label="Promoter Password"
          onChange={(evt) =>
            setNewPromoter({
              ...newPromoter,
              password: evt.target.value,
            })
          }
        />
        <TextField
          className={styles.documentInput}
          label="Promoter Phone Number (Optional)"
          onChange={(evt) =>
            setNewPromoter({
              ...newPromoter,
              primaryPhoneNumber: evt.target.value,
            })
          }
        />
        <div className={styles.createDocumentButtonContainer}>
          <button
            className={styles.createDocumentButton}
            onClick={() => handleCreatePromoter(newPromoter, setCreateNewPromoterModal, props.refreshPromoters)}
          >
            Create Promoter
          </button>
        </div>
      </div>
    </div>
  )
})

CreateNewPromoterModal.displayName = 'CreateNewPromoterModal'

CreateNewPromoterModal.propTypes = {
  refreshPromoters: PropTypes.object,
  setCreateNewPromoterModal: PropTypes.func,
}
