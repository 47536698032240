import axios from 'axios'
import * as R from 'ramda'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import { useState } from 'react'
import TextField from '@material-ui/core/TextField'

import constants from 'src/constants'
import { isValidEmail } from 'src/utils/validate-email'

import styles from './index.module.css'

const handleInvitePromoters = async (promoterEmails, setInviteNewPromotersModal, refreshPromoters) => {
  // TODO: We should do form validation and errors in the form instead of window alerts
  if (promoterEmails.length === 0) {
    window.alert('Please enter an email to invite')
    return
  }

  for (const email of promoterEmails) {
    if (isValidEmail(email)) {
      await axios.post(constants.routes.provider.invitePromoterUser, { email })
    }
  }

  setInviteNewPromotersModal(false)

  refreshPromoters.setToggle(!refreshPromoters.toggle)
}

export const InviteNewPromotersModal = React.forwardRef((props, ref) => {
  const { setInviteNewPromotersModal } = props

  const [promoterEmails, setPromoterEmails] = useState([''])

  return (
    <div className={styles.uploadPdfModalContainer} ref={ref}>
      <div className={styles.modalHeaderContainer}>
        <p>Invite Promoters</p>
        <IconButton
          onClick={() => {
            setInviteNewPromotersModal(false)
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.inputsContainer}>
        {promoterEmails.map((promoterEmail, index) => {
          return (
            <TextField
              defaultValue={promoterEmail}
              key={index}
              className={styles.documentInput}
              label="Promoter Email"
              onChange={(evt) => {
                const newPromoterEmails = R.clone(promoterEmails)
                newPromoterEmails[index] = evt.target.value

                if (R.last(newPromoterEmails) !== '' && newPromoterEmails.length < 5) {
                  newPromoterEmails.push('')
                }

                setPromoterEmails(newPromoterEmails)
              }}
            />
          )
        })}
        <div className={styles.createDocumentButtonContainer}>
          <button
            className={styles.createDocumentButton}
            onClick={() => handleInvitePromoters(promoterEmails, setInviteNewPromotersModal, props.refreshPromoters)}
          >
            Create Promoter
          </button>
        </div>
      </div>
    </div>
  )
})

InviteNewPromotersModal.displayName = 'InviteNewPromotersModal'

InviteNewPromotersModal.propTypes = {
  refreshPromoters: PropTypes.object,
  setInviteNewPromotersModal: PropTypes.func,
}
