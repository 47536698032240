import { useHistory } from 'react-router-dom'

import { signOut } from 'src/utils/sign-out'

import styles from './index.module.css'

export function ProviderAccountsHeader() {
  const history = useHistory()

  return (
    <div className={styles.container}>
      <h1 className={styles.accountsHeaderTitle}>My Account</h1>
      <button className={styles.button} onClick={() => signOut(history)}>
        Sign Out
      </button>
      <div className={styles.underline} />
    </div>
  )
}
