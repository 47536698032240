import { Route, Switch } from 'react-router-dom'

import { HeaderNav } from './header-nav'

import { PromoterDocumentDetails } from './document-details'
import { PromoterDocuments } from './documents'
import { PromoterAccount } from './account'

export function PromoterPages() {
  return (
    <>
      <HeaderNav />
      <Switch>
        <Route path="/promoter/documents/:promoterTemplateId">
          <PromoterDocumentDetails />
        </Route>
        <Route path="/promoter/documents">
          <PromoterDocuments />
        </Route>
        <Route path="/promoter/account">
          <PromoterAccount />
        </Route>
      </Switch>
    </>
  )
}
