import Cookies from 'js-cookie'
import { Redirect, useLocation } from 'react-router-dom'

import { DocumentsHeader } from './documents-header'
import { DocumentsDisplay } from './documents-display'

import styles from './index.module.css'

export function PromoterDocuments() {
  const loggedIn = Cookies.get('piecekeeper-logged-in')

  const location = useLocation()

  if (!loggedIn) return <Redirect to={`/sign-in?redirectUrl=${location.pathname}`} />

  return (
    <div className={styles.documentsContainer}>
      <DocumentsHeader />
      <DocumentsDisplay />
    </div>
  )
}
