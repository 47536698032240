import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import PropTypes from 'prop-types'
import { useState } from 'react'
import TextField from '@material-ui/core/TextField'

import constants from '../../../../constants'
import { makeStyles } from '@material-ui/core/styles'

import * as materialStyles from './styles'
import styles from './index.module.css'

const useMaterialStyles = makeStyles({
  missingTextsHelperText: materialStyles.missingTexts.helperText,
  missingTextsInput: materialStyles.missingTexts.input,
  missingTextsRoot: materialStyles.missingTexts.root,
  saveTextButton: materialStyles.missingTexts.button,
  texts: materialStyles.texts.root,
})

const TextMissing = (props) => {
  const {
    index,
    imageIndex,
    setTextFieldToUpdate,
    setUpdateTextModalOpen,
    saveTextModalOpen,
    text,
    textsStyles,
  } = props

  const [input, setInput] = useState(text.text)

  const isMissing = text.text === null

  const handleTextSave = () => {
    setTextFieldToUpdate({
      index,
      label: text.label,
      providerPdfTemplateTextId: text.providerPdfTemplateTextId,
      text: text.text,
      type: text.type,
      value: input,
    })
    setUpdateTextModalOpen(!saveTextModalOpen)
  }

  const inputLabel = () => {
    if (text.type.includes('customText')) {
      return text?.label || constants.textTypeToLabelMapping[text.type]
    }
    if (text.type.includes('Custom')) {
      const labels = text.type.split('Custom')
      return constants.textTypeToLabelMapping[labels[0]]
    }

    return constants.textTypeToLabelMapping[text.type]
  }

  return (
    <div className={styles.textContainer} key={index}>
      <div className={isMissing ? styles.numberMissing : styles.textNumber}>{imageIndex}</div>
      <FormControl classes={{ root: isMissing ? textsStyles.missingTextsRoot : textsStyles.texts }}>
        <InputLabel>{inputLabel()}</InputLabel>
        <Input
          classes={{ root: isMissing ? textsStyles.missingTextsInput : null }}
          id="standard-adornment-password"
          type="text"
          value={input}
          onChange={(event) => setInput(event.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <Button
                classes={{ root: textsStyles.saveTextButton }}
                className={styles.buttonDisabled}
                onClick={handleTextSave}
                type="button"
              >
                Save
              </Button>
            </InputAdornment>
          }
        />
      </FormControl>
      {isMissing && (
        <FormHelperText classes={{ root: textsStyles.missingTextsHelperText }}>
          {inputLabel()} is required
        </FormHelperText>
      )}
    </div>
  )
}

const PromoterText = ({ index, imageIndex, text, textsStyles }) => {
  return (
    <div className={styles.textContainer} key={index}>
      <div className={styles.number}>{imageIndex}</div>
      <TextField
        classes={{
          root: textsStyles.texts,
        }}
        defaultValue={text.text}
        InputProps={{
          readOnly: true,
        }}
        label={text.text}
        variant="filled"
      />
    </div>
  )
}

export const Texts = (props) => {
  const { texts } = props
  const [updatedText, setSaveUpdatedText] = useState(null)

  const textsStyles = useMaterialStyles()

  return (
    <div className={styles.selectionContainer}>
      {texts.map((text, index) => {
        return (
          <TextMissing
            key={index}
            index={index}
            imageIndex={++index}
            text={text}
            setSaveUpdatedText={setSaveUpdatedText}
            setTextFieldToUpdate={props.setTextFieldToUpdate}
            setUpdateTextModalOpen={props.setUpdateTextModalOpen}
            textFieldToUpdate={props.textFieldToUpdate}
            textsStyles={textsStyles}
            updatedText={updatedText}
          />
        )
      })}
    </div>
  )
}

Texts.propTypes = {
  setTextFieldToUpdate: PropTypes.func,
  setUpdateTextModalOpen: PropTypes.func,
  textFieldToUpdate: PropTypes.object,
  texts: PropTypes.array,
}

TextMissing.propTypes = {
  imageIndex: PropTypes.number,
  index: PropTypes.number,
  saveTextModalOpen: PropTypes.bool,
  setTextFieldToUpdate: PropTypes.func,
  setUpdateTextModalOpen: PropTypes.func,
  text: PropTypes.object,
  textsStyles: PropTypes.object,
}

PromoterText.propTypes = {
  imageIndex: PropTypes.number,
  index: PropTypes.number,
  setTextFieldToUpdate: PropTypes.func,
  text: PropTypes.object,
  textsStyles: PropTypes.object,
}
