import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import * as materialStyles from './styles'
import styles from './index.module.css'

const useMaterialStyles = makeStyles({
  imageSettingsButton: materialStyles.imageSettingsButton,
})

export const PromoterImage = (props) => {
  const { image, imageIndex, setImageEditorDrawerOpen, setImageToEdit, setEditImageModalOpen } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const promoterImageStyles = useMaterialStyles()

  const type = image.type[0].toUpperCase() + image.type.substring(1)

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
    setImageToEdit(image)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleEditImage = () => {
    handleMenuClose(null)
    setEditImageModalOpen(true)
  }

  const handleChangeImage = () => {
    handleMenuClose(null)
    setImageEditorDrawerOpen(true)
  }

  return (
    <div className={styles.imageContainer}>
      <div className={styles.imageLabelContainer}>
        <div className={styles.number}>{imageIndex}</div>
        <p className={styles.label}>{image.label}</p>
      </div>
      <div className={styles.imageAndSettingsContainer}>
        <div>
          <img
            style={{ height: image.location.height, objectFit: 'cover', width: image.location.width }}
            src={image.s3Location}
            alt={image.type}
          />
        </div>
        <div className={styles.imageSettings}>
          <p>{type}</p>
          <Button
            classes={{ root: promoterImageStyles.imageSettingsButton }}
            color="default"
            onClick={handleMenuClick}
            startIcon={<MoreHorizIcon />}
          />
          <Menu anchorEl={anchorEl} id="edit-image-menu" onClose={handleMenuClose} open={Boolean(anchorEl)} keepMounted>
            <MenuItem onClick={handleChangeImage}>Change Image</MenuItem>
            <MenuItem onClick={handleEditImage}>Edit Image</MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  )
}

PromoterImage.propTypes = {
  image: PropTypes.object,
  imageIndex: PropTypes.number,
  imageToEdit: PropTypes.object,
  setEditImageModalOpen: PropTypes.func,
  setImageEditorDrawerOpen: PropTypes.func,
  setImageToEdit: PropTypes.func,
}
