import axios from 'axios'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import constants from './constants'

const initPendo = ({ userId, promoterId = null, providerId = null }) => {
  try {
    window.pendo.initialize({
      account: {
        id: userId,
        promoterId,
        providerId,
        userId,
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },

      visitor: {
        id: userId,
        promoterId,
        providerId,
        userId,
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
    })
  } catch (err) {
    console.log('pendo sucks', err)
  }
}

export function InitializePendo() {
  const location = useLocation()

  const [pendoInitialized, setPendoInitialized] = useState(false)

  useEffect(() => {
    const loggedIn = Cookies.get('piecekeeper-logged-in')

    const getUserAndInitPendo = async () => {
      try {
        const response = await axios.get(`${constants.routes.user}/user/${loggedIn}`)

        initPendo(response.data)
        setPendoInitialized(true)
      } catch (error) {
        console.log('Issue getting user info')
      }
    }

    if (loggedIn && !pendoInitialized) {
      getUserAndInitPendo()
    }
  }, [location])

  return <></>
}
