import axios from 'axios'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import constants from 'src/constants'

import styles from './index.module.css'

export function ContactInformation(props) {
  const { defaultUserTexts } = props

  const { text: email } = R.find(R.propEq('type', 'email'))(defaultUserTexts) ?? { text: '' }
  const { text: firstName } = R.find(R.propEq('type', 'firstName'))(defaultUserTexts) ?? { text: '' }
  const { text: lastName } = R.find(R.propEq('type', 'lastName'))(defaultUserTexts) ?? { text: '' }
  const { text: phoneNumber } = R.find(R.propEq('type', 'phoneNumber'))(defaultUserTexts) ?? { text: '' }

  const { formState, register, handleSubmit, reset } = useForm({
    defaultValues: {
      email,
      firstName,
      lastName,
      phoneNumber,
    },
    mode: 'all',
  })

  useEffect(() => {
    reset({
      email,
      firstName,
      lastName,
      phoneNumber,
    })
  }, [email, firstName, lastName, phoneNumber])

  const onSubmit = async (data) => {
    const texts = [
      {
        text: data.email,
        type: 'email',
      },
      {
        text: data.firstName,
        type: 'firstName',
      },
      {
        text: data.lastName,
        type: 'lastName',
      },
      {
        text: data.phoneNumber,
        type: 'phoneNumber',
      },
      {
        text: data.firstName + ' ' + data.lastName,
        type: 'fullName',
      },
    ]

    const filteredTexts = R.filter(R.compose(R.not, R.isEmpty, R.prop('text')), texts)

    await axios.post(constants.routes.promoter.saveInfo, { texts: filteredTexts })

    reset(data)
  }

  return (
    <div className={styles.contactInformationContainer}>
      <h1 className={styles.contactInformationTitle}>Default Contact Information</h1>
      <div className={styles.underline} />
      <form>
        <div className={styles.namesContainer}>
          <div className={styles.textBox}>
            <label>First Name</label>
            <input name="firstName" ref={register()} className={styles.input} />
          </div>
          <div className={styles.textBox}>
            <label>Last Name</label>
            <input name="lastName" ref={register()} className={styles.input} />
          </div>
        </div>
        <div className={styles.fullTextBoxContainer}>
          <div className={styles.textBox}>
            <label>Email</label>
            <input name="email" ref={register()} className={styles.fullTextBoxInput} />
          </div>
        </div>
        <div className={styles.fullTextBoxContainer}>
          <div className={styles.textBox}>
            <label>Phone Number</label>
            <input name="phoneNumber" ref={register()} className={styles.fullTextBoxInput} />
          </div>
        </div>
        <button
          disabled={!formState.isDirty}
          className={classNames({
            [styles.button]: true,
            [styles.buttonDisabled]: !formState.isDirty,
          })}
          onClick={handleSubmit(onSubmit)}
          type="submit"
        >
          Save
        </button>
      </form>
    </div>
  )
}

ContactInformation.propTypes = {
  defaultUserTexts: PropTypes.array,
}
