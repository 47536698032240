export const saveTextButton = {
    root: {
        backgroundColor: '#31383E',
        borderRadius: '4px',
        color: '#ffffff',
        height: '40px',
        textTransform: 'uppercase',
        width: '140px',
    }
}

export const imageEditorDrawer = {
    drawer: {
        right: '30%',
        top: `(160 - ${window.scrollY})px`,
        width: '272px',
    },
    root: {
        zIndex: 2,
    }
}

export const imageUploadInput = {
    backgroundColor: '#f7f7f7',
    borderRadius: '4px 4px 0 0',
    width: '100%'
}