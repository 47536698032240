import PropTypes from 'prop-types'

import styles from './index.module.css'

export const TextComponent = ({ newCount, text }) => {
  return (
    <>
      <p style={text.styleOptions} className={styles.text}>
        {text?.label || text.textField}
      </p>

      <div className={styles.textNumber}>{newCount}</div>
    </>
  )
}

TextComponent.propTypes = {
  newCount: PropTypes.number,
  text: PropTypes.object,
}
