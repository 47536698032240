import Modal from '@material-ui/core/Modal'
import * as R from 'ramda'
import constants from 'src/constants'

import { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import { CreatingDocumentModal } from './modals/creating-document-modal'
import { EditImageModal } from './modals/edit-image-modal'
import { ImageEditorDrawer } from './modals/image-editor-drawer'
import { PdfViewer } from './pdf-viewer'
import { RightSidebar } from './right-sidebar'
import { TopMenuBar } from './top-menu-bar'
import { UpdateTextModal } from './modals/update-text-modal'
import { UploadImageModal } from './modals/upload-image-modal'

import styles from './index.module.css'

const TOP_MARGIN = 20

export const PdfEditor = (props) => {
  const { images, rootRef, texts } = props

  const [imageToEdit, setImageToEdit] = useState(null)
  const [editImageModalOpen, setEditImageModalOpen] = useState(false)
  const [creatingDocumentModalOpen, setCreatingDocumentModalOpen] = useState({ isLoading: false, isOpen: false })
  const [imageEditorDrawerOpen, setImageEditorDrawerOpen] = useState(false)
  const [pdfLocation, setPdfLocation] = useState({})
  const [pdfPageNumber, setPdfPageNumber] = useState(1)
  const [saveTextModalOpen, setUpdateTextModalOpen] = useState(false)
  const [scalingFactor, setScalingFactor] = useState({ height: 0, width: 0 })
  const [textFieldToUpdate, setTextFieldToUpdate] = useState({
    id: null,
    index: null,
    text: null,
    type: null,
    value: null,
  })
  const [uploadImageModalOpen, setUploadImageModalOpen] = useState(false)
  const { pathname } = useLocation()

  const promoterPdfTemplateId = R.compose(R.last(), R.split('/'))(pathname)

  function getTextWidth({ fontFamily, fontSize, text }) {
    const textToMeasure = document.createElement('span')
    document.body.appendChild(textToMeasure)

    textToMeasure.style.fontFamily = fontFamily
    textToMeasure.style.fontSize = fontSize + 'px'
    textToMeasure.style.height = 'auto'
    textToMeasure.style.width = 'auto'
    textToMeasure.style.position = 'absolute'
    textToMeasure.style.whiteSpace = 'no-wrap'
    textToMeasure.innerHTML = text

    const textWidth = Math.ceil(textToMeasure.clientWidth)
    const formattedWidth = textWidth

    document.body.removeChild(textToMeasure)

    return formattedWidth
  }

  return (
    <div>
      <TopMenuBar
        images={images}
        creatingDocumentModalOpen={creatingDocumentModalOpen}
        pdfPageNumber={pdfPageNumber}
        promoterPdfTemplateId={promoterPdfTemplateId}
        s3PdfLocation={props.s3PdfLocation}
        promoterPdfLocation={props.promoterPdfLocation}
        setCreatingDocumentModalOpen={setCreatingDocumentModalOpen}
        setPromoterPdfLocation={props.setPromoterPdfLocation}
        scalingFactor={scalingFactor}
        setPdfPageNumber={setPdfPageNumber}
        texts={texts}
      />
      <div className={styles.editor}>
        <div className={styles.pdfEditor}>
          <Modal container={() => rootRef.current} open={saveTextModalOpen}>
            <div>
              <UpdateTextModal
                images={images}
                promoterPdfTemplateId={promoterPdfTemplateId}
                setTexts={props.setTexts}
                setUpdatePage={props.setUpdatePage}
                setUpdateTextModalOpen={setUpdateTextModalOpen}
                texts={texts}
                textFieldToUpdate={textFieldToUpdate}
                updatePage={props.updatePage}
              />
            </div>
          </Modal>
          <Modal container={() => rootRef.current} open={editImageModalOpen}>
            <div>
              <EditImageModal
                editImageModalOpen={editImageModalOpen}
                imageToEdit={imageToEdit}
                images={images}
                promoterPdfTemplateId={promoterPdfTemplateId}
                setEditImageModalOpen={setEditImageModalOpen}
                setImages={props.setImages}
                setImageToEdit={setImageToEdit}
                setUpdatePage={props.setUpdatePage}
                texts={texts}
                updatePage={props.updatePage}
              />
            </div>
          </Modal>
          {images.map((image, index) => {
            const x = pdfLocation.x + image.location.xStart * scalingFactor.width
            const y = image.location.yStart * scalingFactor.height + TOP_MARGIN
            const contatinerStyle = {
              height: image.location.height,
              position: 'absolute',
              transform: `translate(${x}px, ${y}px)`,
              width: image.location.width,
              zIndex: 1,
            }

            const imageStyle =
              image.location.height > image.location.width
                ? { height: image.location.height }
                : { width: image.location.width }

            const imageStyleWithFit = constants.defaultProviderImageId === image.id ? imageStyle : { ...imageStyle }

            return (
              <div key={index} style={contatinerStyle}>
                <img style={imageStyleWithFit} src={image.s3Location} />
              </div>
            )
          })}
          {texts.map((text, index) => {
            const { color, fontFamily, fontSize, fontStyle, fontWeight, textAlign, textDecoration } = text.styleOptions

            const displayIndex = ++index

            const { width, xStart, yStart } = text.location

            const renderedTextWidth = getTextWidth({ fontFamily, fontSize, text: text.text })

            const x = xStart * scalingFactor.width + pdfLocation.x
            const y = yStart * scalingFactor.height + TOP_MARGIN

            const style = {
              position: 'absolute',
              transform: `translate(${x}px, ${y}px)`,
              zIndex: 1,
            }

            const textStyles = {
              color,
              fontFamily,
              fontSize,
              fontStyle,
              fontWeight,
              textAlign,
              textDecoration,
              whiteSpace: 'nowrap',
              width: renderedTextWidth <= width ? width : renderedTextWidth,
            }

            const missingTextStyles = {
              ...textStyles,
              color: '#d10000',
            }

            const availableTextComponent = (
              <>
                <p className={styles.text} style={textStyles}>
                  {text.text}
                </p>
                <div className={styles.number}>{displayIndex}</div>
              </>
            )

            const textMissingComponent = (
              <>
                <p style={missingTextStyles}>
                  [{text?.label || constants.textTypeToLabelMapping[text.type]} is missing]*
                </p>
                <div className={styles.numberMissing}>{displayIndex}</div>
              </>
            )

            const textComponent = text.text === null ? textMissingComponent : availableTextComponent

            return (
              <div key={index} style={style} className={styles.textContainer}>
                {textComponent}
              </div>
            )
          })}
          <PdfViewer
            s3PdfLocation={props.s3PdfLocation}
            pdfLocation={pdfLocation}
            pdfPageNumber={pdfPageNumber}
            setPdfLocation={setPdfLocation}
            setPdfPageNumber={setPdfPageNumber}
            setScalingFactor={setScalingFactor}
          />
        </div>
        <RightSidebar
          imageEditorDrawerOpen={imageEditorDrawerOpen}
          images={props.images}
          texts={texts}
          textFieldToUpdate={textFieldToUpdate}
          setEditImageModalOpen={setEditImageModalOpen}
          setImageEditorDrawerOpen={setImageEditorDrawerOpen}
          setTextFieldToUpdate={setTextFieldToUpdate}
          saveTextModalOpen={saveTextModalOpen}
          setUpdateTextModalOpen={setUpdateTextModalOpen}
          imageToEdit={imageToEdit}
          setImageToEdit={setImageToEdit}
        />
        <ImageEditorDrawer
          imageEditorDrawerOpen={imageEditorDrawerOpen}
          setImageEditorDrawerOpen={setImageEditorDrawerOpen}
          rootRef={rootRef}
          setUploadImageModalOpen={setUploadImageModalOpen}
          uploadImageModalOpen={uploadImageModalOpen}
        />
        <Modal container={() => rootRef.current} open={uploadImageModalOpen}>
          <div>
            <UploadImageModal
              images={images}
              imageToEdit={imageToEdit}
              setImageToEdit={setImageToEdit}
              setImageEditorDrawerOpen={setImageEditorDrawerOpen}
              setImages={props.setImages}
              setUploadImageModalOpen={setUploadImageModalOpen}
              promoterPdfTemplateId={promoterPdfTemplateId}
              texts={texts}
              uploadImageModalOpen={uploadImageModalOpen}
            />
          </div>
        </Modal>
        <CreatingDocumentModal
          creatingDocumentModalOpen={creatingDocumentModalOpen}
          setCreatingDocumentModalOpen={setCreatingDocumentModalOpen}
          promoterPdfLocation={props.promoterPdfLocation}
        />
      </div>
    </div>
  )
}

PdfEditor.propTypes = {
  images: PropTypes.array,
  promoterPdfLocation: PropTypes.string,
  rootRef: PropTypes.func,
  s3PdfLocation: PropTypes.string,
  setImages: PropTypes.func,
  setPromoterPdfLocation: PropTypes.func,
  setTexts: PropTypes.func,
  setUpdatePage: PropTypes.func,
  texts: PropTypes.array,
  updatePage: PropTypes.bool,
}
