import * as R from 'ramda'
import { Rnd } from 'react-rnd'

import PropTypes from 'prop-types'

import * as updateProviderPdfTemplateCommands from './update-provider-pdf-template-commands'

const TOP_MARGIN = 20

export function DraggableText(props) {
  const providerPdfTemplateId = R.last(R.split('/', window.location.pathname))

  const { pdfLocation, text, texts, setTexts, scalingFactor, setCurrentFocus } = props

  const handleTextDragStop = async (draggableData, { currentText, texts }) => {
    const xStart = (draggableData.x + window.scrollX - pdfLocation.x) / scalingFactor.width
    const xEnd = xStart + currentText.location.width / scalingFactor.width
    const yStart = (draggableData.y + draggableData.node.parentElement.offsetTop - pdfLocation.y) / scalingFactor.height

    const newText = {
      ...currentText,
      location: {
        height: currentText.location.height,
        pageX: draggableData.x + window.scrollX,
        pageY: draggableData.y + draggableData.node.parentElement.offsetTop,
        width: currentText.location.width,
        xEnd,
        xStart,
        yStart,
      },
    }

    const newTexts = R.map((text) => {
      return text.id === newText.id ? newText : text
    }, texts)

    setTexts(newTexts)

    await updateProviderPdfTemplateCommands.saveTemplate({ id: providerPdfTemplateId, texts: newTexts })
  }

  const handleTextResizeStop = async ({ currentText, texts, ref, position }) => {
    const width = parseInt(ref.style.width)

    const xStart = (position.x + window.scrollX - pdfLocation.x) / scalingFactor.width
    const xEnd = xStart + currentText.location.width / scalingFactor.width
    const yStart = (position.y + ref.parentElement.offsetTop - pdfLocation.y) / scalingFactor.height

    const newText = {
      ...currentText,
      location: {
        ...currentText.location,
        pageX: position.x + window.scrollX,
        pageY: position.y + ref.parentElement.offsetTop,
        width,
        xEnd,
        xStart,
        yStart,
      },
    }

    const newTexts = R.map((text) => {
      return text.id === newText.id ? newText : text
    }, texts)

    setTexts(newTexts)

    await updateProviderPdfTemplateCommands.saveTemplate({ id: providerPdfTemplateId, texts: newTexts })
  }

  return (
    <>
      <Rnd
        bounds={'.react-pdf__Page__canvas'}
        style={{ zIndex: 2 }}
        size={{ height: '22px', width: text.location.width }}
        minWidth={'130px'}
        key={text.id}
        default={{
          x: pdfLocation.x + text.location.xStart * scalingFactor.width,
          y: text.location.yStart * scalingFactor.height + TOP_MARGIN,
        }}
        enableResizing={{
          left: true,
          right: true,
        }}
        onDragStart={() => setCurrentFocus({ id: text.id, type: 'text' })}
        onDragStop={(_, draggableData) => {
          handleTextDragStop(draggableData, { currentText: text, texts })
        }}
        onResizeStop={(_, __, ref, ___, position) => {
          handleTextResizeStop({ currentText: text, position, ref, texts })
        }}
      >
        {text.component}
      </Rnd>
    </>
  )
}

DraggableText.propTypes = {
  pdfLocation: PropTypes.object,
  scalingFactor: PropTypes.object,
  setCurrentFocus: PropTypes.func,
  setTexts: PropTypes.func,
  text: PropTypes.object,
  texts: PropTypes.array,
}
