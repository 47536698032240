import axios from 'axios'
import Cookies from 'js-cookie'
import IconButton from '@material-ui/core/IconButton'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import InfoIcon from '@material-ui/icons/Info'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Redirect, useLocation, useHistory } from 'react-router-dom'

import constants from 'src/constants'
import { signOut } from 'src/utils/sign-out'

import { PieceKeeperLogoSvg } from '../../images/piece-keeper-logo.js'

import * as materialStyles from './styles'
import styles from './index.module.css'
import { UsersTable } from './users-table'

const useStyles = makeStyles({
  iconButton: materialStyles.iconButton.root,
  leftIcons: materialStyles.icons.left,
  rightIcons: materialStyles.icons.right,
})

export function Admin() {
  const loggedIn = Cookies.get('piecekeeper-logged-in')

  const location = useLocation()

  if (loggedIn !== 'admin') return <Redirect to={`/sign-in?redirectUrl=${location.pathname}`} />

  const [focus, setFocus] = useState({ companyDisplayName: false, email: false, password: false })
  const [seePassword, setSeePassword] = useState(false)
  const history = useHistory()

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      companyDisplayName: '',
      email: '',
      password: '',
    },
  })

  const iconStyles = useStyles()

  const focusStyle = {
    container: {
      borderBottom: '2px solid #1f8289',
      color: '#1f8289',
    },
    label: {
      color: '#1f8289',
    },
  }

  const handleShowPassword = () => {
    setSeePassword(!seePassword)
  }

  const handleFocus = (input) => {
    setFocus({ ...focus, [input]: true })
  }

  const handleBlur = (input) => {
    setFocus({ ...focus, [input]: false })
  }

  const createProviderAccount = async (data) => {
    await axios.post(constants.routes.admin.createProviderUser, {
      companyDisplayName: data.companyDisplayName,
      email: data.email,
      password: data.password,
    })

    reset()

    return
  }

  const setPassword = async (data) => {
    await axios.post(constants.routes.admin.setPassword, {
      email: data.email,
      password: data.password,
    })

    reset()

    return
  }

  return (
    <>
      <div className={styles.signInPage}>
        <div className={styles.signInBox}>
          <div className={styles.signInContainer}>
            <PieceKeeperLogoSvg />
            <div>Admin Console for PROVIDER creation</div>
            <form>
              <div className={styles.userInputsContainer}>
                <div className={styles.userInput} style={focus.email ? focusStyle.container : null}>
                  <MailOutlineIcon classes={{ root: iconStyles.leftIcons }} />
                  <div className={styles.labelAndInput}>
                    <label style={focus.email ? focusStyle.label : null}>Email Address</label>
                    <input
                      name="email"
                      onBlur={() => handleBlur('email')}
                      onFocus={() => handleFocus('email')}
                      ref={register({ required: true })}
                    />
                  </div>
                </div>
                <div className={styles.userInput} style={focus.password ? focusStyle.container : null}>
                  <LockOutlinedIcon classes={{ root: iconStyles.leftIcons }} />
                  <div className={styles.labelAndInput}>
                    <label style={focus.password ? focusStyle.label : null}>Password</label>
                    <input
                      name="password"
                      onBlur={() => handleBlur('password')}
                      onFocus={() => handleFocus('password')}
                      ref={register({ required: true })}
                      type={seePassword ? 'text' : 'password'}
                    />
                  </div>
                  <IconButton
                    classes={{ root: iconStyles.iconButton }}
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                  >
                    {seePassword ? (
                      <VisibilityOff classes={{ focused: iconStyles.testIcons, root: iconStyles.rightIcons }} />
                    ) : (
                      <Visibility classes={{ focused: iconStyles.testIcons, root: iconStyles.rightIcons }} />
                    )}
                  </IconButton>
                </div>
                <div className={styles.userInput} style={focus.companyDisplayName ? focusStyle.container : null}>
                  <InfoIcon classes={{ root: iconStyles.leftIcons }} />
                  <div className={styles.labelAndInput}>
                    <label style={focus.companyDisplayName ? focusStyle.label : null}>
                      Company Display Name (Optional)
                    </label>
                    <input
                      name="companyDisplayName"
                      onBlur={() => handleBlur('companyDisplayName')}
                      onFocus={() => handleFocus('companyDisplayName')}
                      ref={register({ required: false })}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button
                  className={styles.createAccountButton}
                  onClick={handleSubmit(createProviderAccount)}
                  variant="contained"
                >
                  Create Account
                </button>
                <button className={styles.setPasswordButton} onClick={handleSubmit(setPassword)} variant="contained">
                  Set Password
                </button>
              </div>
            </form>
            <button className={styles.signOutButton} onClick={() => signOut(history)} variant="contained">
              SIGN OUT
            </button>
          </div>
        </div>
      </div>
      <UsersTable />
    </>
  )
}
