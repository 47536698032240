export const icons = {
  left: {
    margin: '0 16px 0 16px',
  },
  right: {
    color: '#000000',
    margin: 'auto',
  },
}

export const iconButton = {
  root: {
    'border-radius': 0,
    margin: '0 12px 0 16px',
  },
}
