import { useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { Document, Page, pdfjs } from 'react-pdf'

import styles from './index.module.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const Pdf = (props) => {
  const { pdfS3Location, pdfLocation, pdfPageNumber, setPdfLocation, setScalingFactor, setNumPages } = props

  const pdfData = useRef(null)

  useLayoutEffect(() => {
    const boundingClientRect = pdfData.current.getBoundingClientRect()

    setPdfLocation({
      x: boundingClientRect.x + window.scrollX,
      y: boundingClientRect.y + window.scrollY,
    })
  }, [pdfS3Location])

  return pdfS3Location ? (
    <div className={styles.pdfContainer} ref={pdfData}>
      <Document
        className="document"
        file={pdfS3Location}
        onLoadError={console.error}
        onLoadSuccess={({ numPages }) => {
          setNumPages(numPages)
        }}
      >
        <Page
          onLoadSuccess={(pdf) => {
            const height = pdf.height / pdf.originalHeight
            const width = pdf.width / pdf.originalWidth

            setScalingFactor({ height, width })
            setPdfLocation({
              ...pdfLocation,
              bottom: pdfLocation.y + pdf.height,
              height: pdf.height,
              left: pdfLocation.x,
              right: pdfLocation.x + pdf.width,
              top: pdfLocation.y,
            })
          }}
          pageNumber={pdfPageNumber}
          width={800}
        />
      </Document>
    </div>
  ) : null
}

Pdf.propTypes = {
  pdfLocation: PropTypes.object,
  pdfPageNumber: PropTypes.number,
  pdfS3Location: PropTypes.string,
  setNumPages: PropTypes.func,
  setPdfLocation: PropTypes.func,
  setPdfPageNumber: PropTypes.func,
  setScalingFactor: PropTypes.func,
}
