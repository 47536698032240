export const accordion = {
  root: {
    'box-shadow': 'none',
  },
}

export const deleteText = {
  color: '#333333',
}

export const deleteImageIcon = {
  root: {
    cursor: 'pointer',
    minWidth: 'none',
  },
}

export const styleInput = {
  halfInput: {
    margin: '15px auto 0 auto',
    width: '45%',
  },
  inputButtons: {
    margin: '2px',
    padding: '7px',
  },
  inputButtonsContainer: {
    margin: '15px 0 0 0',
    padding: '15px 5px 0 0',
    width: '50%',
  },
  root: {
    'background-color': '#f7f7f7',
    margin: '0 auto 0 auto',
    width: '100%',
  },
  withTopMargin: {
    'background-color': '#f7f7f7',
    margin: '15px auto 0 auto',
    width: '100%',
  },
}

export const list = {
  root: {
    padding: 0,
  },
}

export const tab = {
  root: {
    flex: 1,
    'min-width': '10px',
  },
}

export const tabNavigation = {
  flexContainer: {
    'border-bottom': 'solid #979797 1px',
    'justify-content': 'space-between',
  },
  root: {
    margin: '0 auto 0 auto',
    width: '95%',
  },
}

export const imageSelect = {
  root: {
    backgroundColor: '#f7f7f7',
    margin: '10px 20px 0 0',
    width: '100%',
  },
}
