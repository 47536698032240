import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import constants from 'src/constants'

import styles from './index.module.css'

export const Groups = (props) => {
  const { promoterGroups = [], setPromoterGroups, setShowAddNewGroupModal } = props

  useEffect(() => {
    async function getProvidersGroups() {
      try {
        const { data } = await axios.get('/api/provider/groups')

        setPromoterGroups(data)
      } catch (error) {
        console.log('Could not get promoters information!', error)
      }
    }

    getProvidersGroups()
  }, [])

  const handleAddNew = () => {
    setShowAddNewGroupModal(true)
  }

  return (
    <div className={styles.groupsContainer}>
      <div className={styles.groupsHeader}>
        <h2>Groups</h2>
        <button className={styles.button} onClick={handleAddNew}>
          <AddIcon className={styles.addIcon} />
          Add New
        </button>
      </div>
      <div className={styles.groupsTableContainer}>
        <hr />
        {promoterGroups.map((group) => {
          if (group.id === constants.groups.everyone.id) {
            return (
              <div key={group.id} className={styles.groupContainer}>
                <p>{group.displayName}</p>
              </div>
            )
          }

          return (
            <div key={group.id} className={styles.groupContainer}>
              <p>{group.displayName}</p>
              {/* <ProviderGroupOptions /> */}
            </div>
          )
        })}
      </div>
    </div>
  )
}

Groups.propTypes = {
  promoterGroups: PropTypes.object,
  setPromoterGroups: PropTypes.func,
  setShowAddNewGroupModal: PropTypes.func,
  showAddNewGroupModal: PropTypes.bool,
}

// const options = ['Rename']
// const ITEM_HEIGHT = 48

// const ProviderGroupOptions = () => {
//   const [anchorEl, setAnchorEl] = useState(null)
//   const open = Boolean(anchorEl)

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget)

//     event.stopPropagation()
//   }

//   const handleClose = async (event, option = '') => {
//     setAnchorEl(null)
//     event.stopPropagation()

//     // setSelectedPromoterId(promoter?.promoterId)

//     // if (option.toLowerCase() === 'rename') {
//     //   setShowEditPromoterGroupsModal(true)
//     // }
//   }

//   return (
//     <div>
//       <IconButton aria-label="more" aria-controls="menu" aria-haspopup="true" onClick={handleClick}>
//         <MoreHorizIcon style={{ fill: 'black' }} />
//       </IconButton>
//       <Menu
//         id="menu"
//         anchorEl={anchorEl}
//         keepMounted
//         open={open}
//         onClose={handleClose}
//         PaperProps={{
//           style: {
//             maxHeight: ITEM_HEIGHT * 4.5,
//           },
//         }}
//       >
//         {options.map((option) => (
//           <MenuItem key={option} onClick={(evt) => handleClose(evt, option)}>
//             {option}
//           </MenuItem>
//         ))}
//       </Menu>
//     </div>
//   )
// }
