import { useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { Document, Page, pdfjs } from 'react-pdf'

import styles from './index.module.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const PdfViewer = (props) => {
  const { s3PdfLocation, pdfPageNumber, setPdfPageNumber, setPdfLocation, setScalingFactor } = props

  const pdfData = useRef(null)

  useLayoutEffect(() => {
    let newPdfLocation = {}

    const data = pdfData.current.getBoundingClientRect()

    newPdfLocation = {
      bottom: data.bottom,
      left: data.left,
      right: data.right,
      top: data.top,
      x: data.x,
      y: data.y,
    }

    setPdfLocation(newPdfLocation)
  }, [setPdfLocation])

  return (
    <div className={styles.pdfContainer} ref={pdfData}>
      <Document
        className="document"
        file={s3PdfLocation}
        onLoadError={console.error}
        onLoadSuccess={({ numPages }) => {
          setPdfPageNumber(numPages)
        }}
      >
        <Page
          onLoadSuccess={(pdf) => {
            const height = pdf.height / pdf.originalHeight
            const width = pdf.width / pdf.originalWidth

            setScalingFactor({ height, width })
          }}
          pageNumber={pdfPageNumber}
          width={800}
        />
      </Document>
    </div>
  )
}

PdfViewer.propTypes = {
  initialData: PropTypes.object,
  pdfPageNumber: PropTypes.number,
  s3PdfLocation: PropTypes.string,
  setNumPages: PropTypes.func,
  setPdfLocation: PropTypes.func,
  setPdfPageNumber: PropTypes.func,
  setScalingFactor: PropTypes.func,
}
