import { useEffect, useState } from 'react'
import axios from 'axios'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'

import { EditPromoterGroupsModal } from '../modals/edit-promoter-groups-modal'
import { PromotersHeader } from './promoters-header'

import * as materialStyles from './styles.js'
import styles from './index.module.css'

const ITEM_HEIGHT = 48
const options = ['Edit groups']

const useMaterialStyles = makeStyles({
  headerCells: materialStyles.header.cells,
  tableHeader: materialStyles.header.root,
})

export const Promoters = () => {
  const [toggle, setToggle] = useState(false)
  const [selectedPromoterId, setSelectedPromoterId] = useState(null)
  const [showEditPromoterGroupsModal, setShowEditPromoterGroupsModal] = useState(false)

  const [promoters, setPromoters] = useState([])
  const promotersStyles = useMaterialStyles()

  useEffect(() => {
    async function getPromotersInfo() {
      try {
        const { data } = await axios.get('/api/provider/promoters')

        setPromoters(data)
      } catch (error) {
        console.log('Could not get promoters information!', error)
      }
    }

    getPromotersInfo()
  }, [toggle])

  return (
    <div className={styles.promotersContainer}>
      <EditPromoterGroupsModal
        promoters={promoters}
        selectedPromoterId={selectedPromoterId}
        showEditPromoterGroupsModal={showEditPromoterGroupsModal}
        setShowEditPromoterGroupsModal={setShowEditPromoterGroupsModal}
      />
      <PromotersHeader refreshPromoters={{ setToggle, toggle }} />
      <div className={styles.table}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead classes={{ root: promotersStyles.tableHeader }}>
              <TableRow>
                <TableCell classes={{ root: promotersStyles.headerCells }}>Name</TableCell>
                <TableCell classes={{ root: promotersStyles.headerCells }} align="left">
                  Email
                </TableCell>
                <TableCell classes={{ root: promotersStyles.headerCells }} align="left">
                  Phone Number
                </TableCell>
                <TableCell classes={{ root: promotersStyles.headerCells }} align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promoters.map((promoter, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="promoter">
                    {promoter.displayName}
                  </TableCell>
                  <TableCell align="left">{promoter.email}</TableCell>
                  <TableCell align="left">{promoter.primaryPhoneNumber}</TableCell>
                  <TableCell align="left">
                    <PromoterOptions
                      promoter={promoter}
                      selectedPromoterId={selectedPromoterId}
                      setSelectedPromoterId={setSelectedPromoterId}
                      showEditPromoterGroupsModal={showEditPromoterGroupsModal}
                      setShowEditPromoterGroupsModal={setShowEditPromoterGroupsModal}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

function PromoterOptions(props) {
  const {
    promoter,
    selectedPromoterId,
    setSelectedPromoterId,
    showEditPromoterGroupsModal,
    setShowEditPromoterGroupsModal,
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)

    event.stopPropagation()
  }

  const handleClose = async (event, option = '') => {
    setAnchorEl(null)
    event.stopPropagation()

    setSelectedPromoterId(promoter?.promoterId)

    if (option.toLowerCase() === 'edit groups') {
      setShowEditPromoterGroupsModal(true)
    }
  }

  return (
    <div>
      <IconButton aria-label="more" aria-controls="menu" aria-haspopup="true" onClick={handleClick}>
        <MoreHorizIcon style={{ fill: 'black' }} />
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={(evt) => handleClose(evt, option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
