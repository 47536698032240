import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'js-cookie'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'

import constants from 'src/constants'

import * as materialStyles from './styles.js'
import styles from './index.module.css'

const useMaterialStyles = makeStyles({
  headerCells: materialStyles.header.cells,
  tableHeader: materialStyles.header.root,
})

export const UsersTable = () => {
  const history = useHistory()
  const [users, setUsers] = useState([])
  const [updateUser, setUpdateUser] = useState(true)
  const userStyles = useMaterialStyles()

  useEffect(() => {
    async function getUsersInfo() {
      try {
        const { data } = await axios.get('/api/admin/users')

        setUsers(data)
      } catch (error) {
        console.log('Could not get users information!', error)
      }
    }

    getUsersInfo()
  }, [updateUser])

  const impersonateUser = async (userId) => {
    await axios.post(`${constants.routes.admin.impersonate}/${userId}`)

    const loggedIn = Cookies.get('piecekeeper-logged-in')

    if (loggedIn === 'promoter') {
      history.push('/promoter/documents')
    }

    if (loggedIn === 'provider') {
      history.push('/provider/documents')
    }
  }

  const resetUserMetrics = async (userId) => {
    await axios.post(constants.routes.admin.resetUserMetrics, { userId })

    const newUsers = users.map((user) => {
      if (user.id === userId) {
        user.numberOfUploads = 0
      }

      return user
    })

    setUsers(newUsers)
  }

  const deleteProviderWithNoPromoters = async (userId) => {
    await axios
      .post(`${constants.routes.admin.deleteProviderWithNoPromoters}/${userId}`, {
        validateStatus: (status) => {
          return status === 200 || status === 500
        },
      })
      .then(() => {
        setUpdateUser(!updateUser)
        alert('🟢 Provider data was deleted 🟢')
      })
      .catch((error) => {
        console.log(error)
        if (error.response?.data && error.response?.status) alert(`🔴 ${error.response.data}. No data was deleted. 🔴`)
      })
  }

  return (
    <div className={styles.usersContainer}>
      <div className={styles.table}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead classes={{ root: userStyles.tableHeader }}>
              <TableRow>
                <TableCell classes={{ root: userStyles.headerCells }}>Company Name</TableCell>
                <TableCell classes={{ root: userStyles.headerCells }} align="left">
                  Email
                </TableCell>
                <TableCell classes={{ root: userStyles.headerCells }} align="left">
                  Provider Id
                </TableCell>
                <TableCell classes={{ root: userStyles.headerCells }} align="left">
                  Promoter Id
                </TableCell>
                <TableCell classes={{ root: userStyles.headerCells }} align="left">
                  User Id
                </TableCell>
                <TableCell classes={{ root: userStyles.headerCells }} align="left">
                  Number of Promoters
                </TableCell>
                <TableCell classes={{ root: userStyles.headerCells }} align="left">
                  Number of Assets
                </TableCell>
                <TableCell classes={{ root: userStyles.headerCells }} align="left">
                  User Display Name
                </TableCell>
                <TableCell classes={{ root: userStyles.headerCells }} align="left">
                  Impersonate User
                </TableCell>
                <TableCell classes={{ root: userStyles.headerCells }} align="left">
                  Reset Upload Count
                </TableCell>
                <TableCell classes={{ root: userStyles.headerCells }} align="left">
                  DELETE USER
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.userId}>
                  <TableCell component="th" scope="user">
                    {user.companyDisplayName ?? 'NULL'}
                  </TableCell>
                  <TableCell align="left">{user.email}</TableCell>
                  <TableCell align="left">{user.providerId ?? 'NULL'}</TableCell>
                  <TableCell align="left">{user.promoterId ?? 'NULL'}</TableCell>
                  <TableCell align="left">{user.userId}</TableCell>
                  <TableCell align="left">{user.numberOfPromoters}</TableCell>
                  <TableCell align="left">{user.numberOfAssets}</TableCell>
                  <TableCell align="left">{user.displayName ?? 'NULL'}</TableCell>
                  <TableCell align="left">
                    <button
                      onClick={() => {
                        impersonateUser(user.userId)
                      }}
                    >
                      Impersonate User
                    </button>
                  </TableCell>
                  <TableCell align="left">
                    {user.providerId ? (
                      <button
                        onClick={() => {
                          resetUserMetrics(user.userId)
                        }}
                      >
                        {`Reset Upload Count (${user.numberOfUploads})`}
                      </button>
                    ) : (
                      'NULL'
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {user.providerId && user.numberOfPromoters === 0 ? (
                      <button onClick={() => deleteProviderWithNoPromoters(user.userId)}>Delete Provider</button>
                    ) : (
                      'Cannot delete user'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}
