import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import * as R from 'ramda'

import { updatePromoterPdfTemplate } from '../../commands'

import { makeStyles } from '@material-ui/core/styles'

import constants from 'src/constants'

import * as materialStyles from './styles'
import styles from './update-text-modal.module.css'

const useMaterialStyles = makeStyles({
  saveButtonText: materialStyles.saveTextButton.root,
})

export function UpdateTextModal(props) {
  const {
    promoterPdfTemplateId,
    setTexts,
    setUpdatePage,
    setUpdateTextModalOpen,
    textFieldToUpdate,
    texts,
    updatePage,
  } = props
  const updateTextStyles = useMaterialStyles()

  const handleUpdateTextClick = async () => {
    const updatedTexts = R.map((text) => {
      if (text.providerPdfTemplateTextId === textFieldToUpdate.providerPdfTemplateTextId) {
        // only update the to a custom type if input is different than default
        if (text.text !== textFieldToUpdate.value) {
          const type =
            text.type.includes('Custom') || text.type.includes('custom') ? text.type : text.type.concat('Custom')

          return { ...text, text: textFieldToUpdate.value, type }
        }

        return { ...text, text: textFieldToUpdate.value }
      }

      return text
    }, texts)

    await updatePromoterPdfTemplate({
      promoterPdfTemplateId,
      promoterPdfTemplateUpdates: { texts: updatedTexts },
    })

    setTexts(updatedTexts)
    setUpdateTextModalOpen(false)
    setUpdatePage(!updatePage)
  }

  const textFieldLabel = () => {
    if (textFieldToUpdate.type === 'customText') {
      return textFieldToUpdate?.label || constants.textTypeToLabelMapping[textFieldToUpdate.type]
    }
    if (textFieldToUpdate.type.includes('Custom')) {
      const labels = textFieldToUpdate.type.split('Custom')
      return constants.textTypeToLabelMapping[labels[0]]
    }

    return constants.textTypeToLabelMapping[textFieldToUpdate.type]
  }

  return (
    <div className={styles.updateTextModalContainer}>
      <div className={styles.confirmationContainer}>
        <p>Confirmation</p>
        <IconButton onClick={() => setUpdateTextModalOpen(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.updateTextContainer}>
        <p>This will update all &quot{textFieldLabel()}&quot fields on this document only.</p>
        <Button classes={{ root: updateTextStyles.saveButtonText }} onClick={handleUpdateTextClick}>
          Update Fields
        </Button>
      </div>
    </div>
  )
}

UpdateTextModal.propTypes = {
  promoterPdfTemplateId: PropTypes.string,
  setTexts: PropTypes.func,
  setUpdatePage: PropTypes.func,
  setUpdateTextModalOpen: PropTypes.func,
  textFieldToUpdate: PropTypes.object,
  texts: PropTypes.array,
  updatePage: PropTypes.bool,
}
