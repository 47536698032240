import { Route, Switch } from 'react-router-dom'

import { HeaderNav } from './header-nav'

import { ProviderDocumentDetails } from './document-details'
import { ProviderDocuments } from './documents'
import { Promoters } from './promoters'
import { ProviderAccount } from './account'

export function ProviderPages() {
  return (
    <>
      <HeaderNav />
      <Switch>
        <Route path="/provider/documents/:providerPdfTemplateId">
          <ProviderDocumentDetails />
        </Route>
        <Route path="/provider/documents">
          <ProviderDocuments />
        </Route>
        <Route path="/provider/promoters">
          <Promoters />
        </Route>
        <Route path="/provider/account">
          <ProviderAccount />
        </Route>
      </Switch>
    </>
  )
}
