import Modal from '@material-ui/core/Modal'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { CreateNewPromoterModal } from '../create-new-promoter-modal'
import { InviteNewPromotersModal } from '../invite-new-promoters-modal'

import styles from './index.module.css'

export function PromotersHeader(props) {
  const [createNewPromoterModal, setCreateNewPromoterModal] = useState(false)
  const [inviteNewPromotersModal, setInviteNewPromotersModal] = useState(false)

  return (
    <div className={styles.container}>
      <h1 className={styles.promotersHeaderTitle}>Promoters</h1>
      <div className={styles.buttonsContainer}>
        <button className={styles.button} onClick={() => setInviteNewPromotersModal(true)}>
          INVITE NEW PROMOTERS
        </button>
        {/* TODO: Remove this and related code if we end up wanting to permanently remove this functionality */}
        {/* <button className={styles.button} onClick={() => setCreateNewPromoterModal(true)}>
          ADD NEW
        </button> */}
      </div>
      <div className={styles.underline} />
      <Modal open={inviteNewPromotersModal}>
        <>
          <InviteNewPromotersModal
            setInviteNewPromotersModal={setInviteNewPromotersModal}
            refreshPromoters={props.refreshPromoters}
          />
        </>
      </Modal>
      <Modal open={createNewPromoterModal}>
        <>
          <CreateNewPromoterModal
            setCreateNewPromoterModal={setCreateNewPromoterModal}
            refreshPromoters={props.refreshPromoters}
          />
        </>
      </Modal>
    </div>
  )
}

PromotersHeader.propTypes = {
  refreshPromoters: PropTypes.object,
}
